import React, { useEffect, useState } from "react";
import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Button, ModalFooter, Tooltip, Spinner } from "reactstrap"
import { Menu } from "components/menu";
import { Likes } from "components/Likes";
import { SearchInput } from "components/search";
import { MenuItems } from "components/MenuItems";
import { AnchorScroll } from "helper";
import { Link, useHistory } from "react-router-dom";
import Config from '../../config/Config'
import Avatar from "react-avatar";
import { Service, Storage } from "services/service";
import { Controller, useForm } from "react-hook-form";
import FileAvatar from "../../components/FileAvatar";

const Cybot = () => {

    let userData = Storage.get("userData")

    const history = useHistory()

    const [resultFiles, setResultFiles] = useState()

    const [trigger, setTrigger] = useState(false)
    const [reloadDiscover, setReloadDiscover] = useState(false)

    //ToolTip
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const [communityData, setCommunityData] = useState([]);

    const [editCommuntiyIndex, setEditCommuntiyIndex] = useState([])
    const [deleteCommuntiyIndex, setDeleteCommuntiyIndex] = useState([])

    const [editCommunity, setEditCommunity] = useState(false)
    const [deleteCommunity, setDeleteCommunity] = useState(false)

    const [editImage, setEditImage] = useState("")

    const editCommunityToggle = () => setEditCommunity(!editCommunity)
    const deleteCommunityToggle = () => setDeleteCommunity(!deleteCommunity)

    const [deleteCommunityName, setDeleteCommunityName] = useState([])

    const { control, handleSubmit, setValue } = useForm();

    const [pageLoading, setPageLoading] = useState(false)

    useEffect(() => {
        AnchorScroll();
    }, [])

    useEffect(() => {
        getAllCommunity(true);
        setReloadDiscover(false);
    }, [reloadDiscover && reloadDiscover])

    const getAllCommunity = (item) => {
        setPageLoading(item)
        if (userData && userData.r === "n") {
            Service.post({ url: '/activity/groups/search', body: JSON.stringify() })
                .then((response) => {
                    setCommunityData(response.data.filter((item) => item.isFactionGroup === 0))
                    setTrigger(false)
                    setPageLoading(false)
                }).catch((error) => {
                    setPageLoading(false)
                })
        } else {
            const params = {
                limit: 1000,
                page: 1
            }
            Service.post({ url: '/activity/groups/joinedGroup/search', body: JSON.stringify(params) })
                .then((response) => {
                    const getAdminGroup1 = response.data.filter((item) => item.isAdmin === 1)
                    if (getAdminGroup1 && getAdminGroup1.length > 0) {
                        setCommunityData(getAdminGroup1)
                        setTrigger(false)
                        setPageLoading(false)
                    } else {
                        setCommunityData([])
                        setTrigger(false)
                        setPageLoading(false)
                    }
                }).catch((error) => {
                    setPageLoading(false)
                })
        }
    }

    const leaveCommunity = (item) => {
        Service.delete({ url: `/activity/userSocialGroup/${item.id}`, body: JSON.stringify() })
            .then((response) => {
                getAllCommunity(null)
                setTrigger(true)
            }).catch((error) => { })
    }

    const editCommunityDetails = (item) => {
        editCommunityToggle();
        setValue("GroupName", item.GroupName)
        setValue("GroupDescription", item.GroupDescription)
        setEditCommuntiyIndex(item.id)
        setEditImage(item.GroupImage)
    }

    const deleteCommunityDetails = (item) => {
        deleteCommunityToggle();
        setDeleteCommuntiyIndex(item.id)
        setDeleteCommunityName(item.GroupName)
    }

    const renderImage = () => {
        if (editImage) {
            return <img src={`${Config.MEDIA_URL}${editImage}`} alt="" width={"200px"} height="100px"></img>
        } else {
            return <img src="" alt="Image not found"></img>
        }
    }

    const onEditCommunitySubmit = (data) => {

        const groupname = data.GroupName
        const groupdescription = data.GroupDescription
        const Image = resultFiles && resultFiles.filePath ? resultFiles.filePath : editImage

        if (groupname === "") {
            return false
        }

        if (groupdescription === "") {
            return false
        }

        const params = {
            groupName: groupname,
            description: groupdescription,
            groupImage: Image
        }
        Service.patch({ url: `/activity/groups/${editCommuntiyIndex}`, body: JSON.stringify(params) })
            .then((response) => {
                editCommunityToggle();
                getAllCommunity(null);
                setTrigger(true)
            }).catch((error) => { })
    }

    const onDeleteCommunitySubmit = () => {
        Service.delete({ url: `/activity/groups/${deleteCommuntiyIndex}`, body: JSON.stringify() })
            .then((response) => {
                deleteCommunityToggle();
                getAllCommunity(null);
                setTrigger(true);
            }).catch((error) => { })
    }

    return (
        <>
            <div className="px-4 bg-right">
                <SearchInput />
                <Row>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="mt-4">
                        <div className="mx-sm-5 px-sm-5 mx-lg-0 px-lg-0">
                            <Menu />
                        </div>
                        <div className="d-none d-lg-block mt-lg-4 stickey">
                            <MenuItems />
                        </div>
                    </Col>
                    <Col xl="6" lg="6" md="12" sm="12" xs="12" >
                        <div className="middle-info-bg h-lg-100 p-3 p-sm-4 mt-4">
                            <h3>Cybot Communities</h3>
                            {
                                pageLoading ? <div className="bg-primary p-3 m-3 text-center"><Spinner color="light" /></div> :
                                    communityData && communityData.length > 0 ?
                                        communityData.map((item) => {
                                            return <div className="bg-primary p-3">
                                                <Row className="d-flex align-items-center">
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <Link to={`/cybot/${item.id}`}>
                                                            <Row>
                                                                <Col lg="3" md="3" sm="3" xs="12" className="text-sm-left">
                                                                    {
                                                                        item.GroupImage ? <img src={`${Config.MEDIA_URL}${item.GroupImage}`} alt="" /> : <Avatar key={item.id} name={item.GroupName.split("").splice(0, 1).join("")} size="50" colors={['#e14eca', '#00f2c3']} />
                                                                    }
                                                                </Col>
                                                                <Col lg="9" md="12" sm="12" xs="12">
                                                                    <Row>
                                                                        <Col lg="9" md="9" sm="10" xs="12" className="">
                                                                            <h5 className="mb-1 mt-3 mt-lg-0">{item.GroupName}</h5>
                                                                            <div className="d-flex">
                                                                                <h6 className="mt-1">{item.GroupDescription}</h6>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="3" md="3" sm="2" xs="12" className="d-flex align-items-center justify-content-end mt-2 mt-sm-0 toggle-icon">
                                                                            <small className="number2 mr-1">{item.notificationCount}</small>
                                                                            <UncontrolledDropdown className='dropdown-user nav-item ml-2' direction="down">
                                                                                <DropdownToggle href='/' tag='a' className='align-items-center' onClick={e => e.preventDefault()}>
                                                                                    <img src={require('.././../assets/img/Landing_Page/menu1.png')} className="" alt="" />
                                                                                </DropdownToggle>
                                                                                <DropdownMenu className="dropdown-menu-right optionpost">
                                                                                    {
                                                                                        userData && userData.r === "n" ? <><DropdownItem to='#' onClick={() => editCommunityDetails(item)}>
                                                                                            <span className='align-middle optionpostitem'>Edit Community</span>
                                                                                        </DropdownItem>
                                                                                            <DropdownItem to='#' onClick={() => deleteCommunityDetails(item)}>
                                                                                                <span className='align-middle optionpostitem'>Delete Community</span>
                                                                                            </DropdownItem></> : <DropdownItem to='#' onClick={() => leaveCommunity(item)}>
                                                                                            <span className='align-middle optionpostitem'>Leave Community</span>
                                                                                        </DropdownItem>
                                                                                    }
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }) : <div className="bg-primary1 p-3 m-3 text-center"><h6>Join Cybot Communities from discover to get updates...</h6>
                                            <Button className="modal-btn" onClick={() => history.push("/discover")}><h6 className="mb-0">Go to Discover</h6></Button></div>
                            }
                        </div>
                    </Col>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="p-0 my-5 mt-sm-0">
                        <Likes trigger={trigger} setReloadDiscover={setReloadDiscover} />
                    </Col>
                </Row>

                {/* <------- Edit Communtiy ------------> */}

                <Modal isOpen={editCommunity} toggle={editCommunityToggle}>
                    <ModalHeader toggle={editCommunityToggle}>Edit Social Community</ModalHeader>
                    <hr className="w-100 modal-line" />
                    <ModalBody>
                        <Form onSubmit={handleSubmit(onEditCommunitySubmit)}>
                            <FormGroup>
                                <Label for="groupname">
                                    Group Name
                                </Label>
                                <Controller
                                    name="GroupName"
                                    control={control}
                                    id="groupname"
                                    render={({ field }) => <Input placeholder="Enter Group Name" type="text" {...field} />}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="groupdescription">
                                    Group Description
                                </Label>
                                <Controller
                                    name="GroupDescription"
                                    control={control}
                                    id="groupdescription"
                                    render={({ field }) => <Input placeholder="Enter Group Description" type="text" {...field} />}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="photo">
                                    Update your Community Photo
                                </Label>
                                <FileAvatar aspectRatio={4 / 2} setResultFiles={setResultFiles} />
                            </FormGroup>
                            <FormGroup>
                                {renderImage()}
                            </FormGroup>
                            <ModalFooter>
                                <Button color="success" type="submit">
                                    Edit
                                </Button>{' '}
                                <Button color="secondary" onClick={editCommunityToggle}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </Form>
                    </ModalBody>
                </Modal>

                {/* <------- Delete Community ------------> */}

                <Modal isOpen={deleteCommunity} toggle={deleteCommunityToggle}>
                    <ModalHeader toggle={deleteCommunityToggle}>Delete Social Community</ModalHeader>
                    <hr className="w-100 modal-line" />
                    <ModalBody>
                        <span>Do you really want to delete the Community {deleteCommunityName}?</span>
                        <ModalFooter>
                            <Button color="success" type="submit" onClick={() => onDeleteCommunitySubmit()}>
                                Delete
                            </Button>{' '}
                            <Button color="secondary" onClick={deleteCommunityToggle}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            </div >
        </>
    )
}

export default Cybot