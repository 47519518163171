import React from 'react'
import { Route, Switch, BrowserRouter } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
// Custom
import Index from "views/home";
import Home from 'views/home/Home';
import Community from 'views/home/Community';
import Feed from 'views/home/Feed';
import Babble from 'views/home/Babble';
import Discover from 'views/home/Discover';
import Community_Sub from 'views/home/Community_Sub';
import EP1 from 'views/home/EP1';
import Admin from 'views/home/Admin';
import Faction from 'views/home/Faction';
import Cybot from 'views/home/Cybot';
import HomeProfile from 'views/home/HomeProfile';
import FactionDetails from 'views/home/FactionDetails';
import CybotProfile from 'views/home/CybotProfile';

export default function Routes() {

    React.useEffect(() => {
        AOS.init();
    });

    return (
        <>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" render={(props) => <Index {...props} />} />
                    <Route exact path="/home" render={(props) => <Home {...props} />} />
                    <Route exact path="/home/:id" render={(props) => <HomeProfile {...props} />} />
                    <Route exact path="/cybot" render={(props) => <Cybot {...props} />} />
                    <Route exact path="/cybot/:id" render={(props) => <CybotProfile {...props} />} />
                    <Route exact path="/community" render={(props) => <Community {...props} />} />
                    <Route exact path="/babble" render={(props) => <Feed {...props} />} />
                    <Route exact path="/discover" render={(props) => <Discover {...props} />} />
                    <Route exact path="/community/:id" render={(props) => <Community_Sub {...props} />} />
                    <Route exact path="/faction" render={(props) => <Faction {...props} />} />
                    <Route exact path="/faction/:id" render={(props) => <FactionDetails {...props} />} />
                    <Route exact path="/EP1" render={(props) => <EP1 {...props} />} />
                    <Route exact path="/admin" render={(props) => <Admin {...props} />} />
                </Switch>
            </BrowserRouter>
        </>
    )
}