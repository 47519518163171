/*!

=========================================================
* outrider - v1.0.0
=========================================================
* Coded by Ecrubit
*/
import React from "react";
import ReactDOM from "react-dom";
import Routes from "routes";

import "assets/css/nucleo-icons.css";
import "assets/scss/outrider.scss?v=1.2.0";
import "assets/demo/demo.css";
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import "flatpickr/dist/themes/material_green.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'
import ChatGPTBot from "./components/chatbot/ChatGPTBot";

const getLibrary = (provider) => {
  const library = new Web3Provider(provider, 'any')
  library.pollingInterval = 15000
  return library
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}> <Routes />
    <ToastContainer newestOnTop />
    <ChatGPTBot firstTime={true} />
    </Web3ReactProvider>,
  document.getElementById("root")
);
