import { Fragment, useState, useCallback, useRef } from 'react'
import { Modal, ModalBody, ModalFooter, Button, Input } from 'reactstrap'
import Cropper from 'react-easy-crop'
import getCroppedImg from './CropImage'
import Config from '../config/Config'
import { BiEdit } from 'react-icons/bi'

const FileAvatar = (props) => {

    const { aspectRatio, onImageSubmit, setResultFiles, opacity } = props
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [btnSubmitted, setBtnSubmitted] = useState(false)
    const [cropModal, setCropModal] = useState(false)
    const [imgSrc, setImgSrc] = useState("")
    const [imgObject, setImgObject] = useState(null)

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
            reader.addEventListener('load', () => setImgSrc(reader.result))
            reader.readAsDataURL(e.target.files[0])
            setImgObject(e.target.files[0])
            setCropModal(!cropModal)
        }
    }

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imgSrc,
                croppedAreaPixels,
                rotation,
                imgObject
            )

            const blobToFile = new File([croppedImage], croppedImage.name, { type: croppedImage.type })
            const formData = new FormData()
            var myHeaders = new Headers();
            formData.append("file", blobToFile)

            setBtnSubmitted(true)

            const token = window.localStorage.getItem('token')
            if (token) {
                myHeaders.append("x-access-token", token)
            }

            const result = await fetch(`${Config.API_BASE_URL}/user/fileUpload`, {
                method: "POST",
                headers: myHeaders,
                body: formData,
                redirect: 'follow'
            })
            if (result) {
                const response = await result.json()
                if (opacity === "profile") {
                    onImageSubmit(response.data.filePath)
                    setBtnSubmitted(false)
                    setCropModal(!cropModal)
                } else {
                    setResultFiles(response.data)
                    setBtnSubmitted(false)
                    setCropModal(!cropModal)
                }
            }
        } catch (e) { }
    }, [croppedAreaPixels, rotation])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    return (
        <Fragment>
            <Input className={opacity === "profile" ? " hide avatarinput cursor-pointer" : "hide avatarinput1 cursor-pointer fileinput"} type='file' id='upload' onChange={onSelectFile} name='upload' accept='image/*'></Input>
            <Modal isOpen={cropModal} toggle={() => setCropModal(!cropModal)}>
                <ModalBody>
                    <div className="crop-container">
                        <Cropper
                            image={imgSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspectRatio}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onRotationChange={setRotation}
                            onZoomChange={setZoom}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className='mr-1' outline color='secondary' type='button' onClick={() => { setCropModal(!cropModal); setBtnSubmitted(false) }}>
                        Close
                    </Button>
                    <Button color='primary' type='button' onClick={() => showCroppedImage()}>
                        {(btnSubmitted) ? "Cropping..." : 'Crop'}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}
export default FileAvatar
