import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { Row, Col, Form, FormGroup, Input, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Label, ModalFooter, Spinner } from "reactstrap"
import { Menu } from "components/menu";
import { Likes } from "components/Likes";
import { SearchInput } from "components/search";
import { MenuItems } from "components/MenuItems";
import { AnchorScroll } from "helper";
import { Service, Storage } from "services/service";
import { Controller, useForm } from "react-hook-form";
import Config from '../../config/Config'
import { useParams, useHistory, Link } from "react-router-dom";
import ReactPlayer from "react-player";
import moment from 'moment'
import { EditorState, ContentState, convertFromHTML, CompositeDecorator } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import { stateToHTML } from 'draft-js-export-html';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css'; // Import the plugin's CSS file
import '@draft-js-plugins/mention/lib/plugin.css';
import { stateFromHTML } from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import { AiOutlinePushpin } from "react-icons/ai";

const CybotProfile = () => {

    const ref = useRef(null);
    const editref = useRef(null)
    const paramsId = useParams()

    let userData = Storage.get("userData")

    const history = useHistory()

    const { control, handleSubmit, setValue } = useForm();

    const [pageLoading, setPageLoading] = useState(false)
    const [sectionLoading, setSectionLoading] = useState(false)

    const [editModal, setEditModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [videoModal, setVideoModal] = useState(false)

    const [communityData, setCommunityData] = useState([]);
    const [communityData1, setCommunityData1] = useState([]);
    const [communityPostData, setCommunityPostData] = useState(null);
    const [resultFiles, setResultFiles] = useState()

    //mentions
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [editEditorState, setEditEditorState] = useState(() => EditorState.createEmpty());
    const [suggestions, setSuggestions] = useState([]);
    const [editSuggestions, setEditSuggestions] = useState([]);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);

    const editToggle = () => setEditModal(!editModal)
    const deleteToggle = () => setDeleteModal(!deleteModal)
    const videoToggle = () => setVideoModal(!videoModal)

    const [editIndex, setEditIndex] = useState()
    const [deleteIndex, setDeleteIndex] = useState()

    const [editValue, setEditValue] = useState()
    const [gettingPost, setGettingPost] = useState()

    const [resultVideoFiles, setVideoResultFiles] = useState()

    const options = {
        entityStyleFn: (entity) => {
            const entityType = entity.get("type").toLowerCase();

            if (entityType === "mention") {
                const { mention } = entity.get("data");
                return {
                    element: "a",
                    attributes: {
                        userid: mention.id,
                        href: `/home/${mention.id}`,
                    },
                };
            }
        },
    };

    useEffect(() => {
        viewCommunityDetails();
        getAllPosts(true);
    }, [paramsId])

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionComponent = ({ entityKey, mention, className, decoratedText }) => {
            return <Link className={className} to={`/home/${mention.id}`}>{decoratedText}</Link>
        };
        const mentionPlugin = createMentionPlugin({
            mentionTrigger: "@", mentionComponent
        });
        const MentionSuggestions = mentionPlugin.MentionSuggestions;

        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    const { plugins1, MentionSuggestions2 } = useMemo(() => {
        const mentionComponent1 = ({ entityKey, mention, className, decoratedText }) => {
            return <Link className={className} to={`/home/${mention.id}`}>{decoratedText}</Link>
        };
        const mentionPlugin2 = createMentionPlugin({
            mentionTrigger: "@", mentionComponent1
        });
        const MentionSuggestions2 = mentionPlugin2.MentionSuggestions;

        const plugins1 = [mentionPlugin2];
        return { plugins1, MentionSuggestions2 };
    }, []);

    const onOpenChange = useCallback((_open) => {
        setOpen(_open);
    }, []);
    const onEditOpenChange = useCallback((_open) => {
        setEditOpen(_open);
    }, []);

    const onSearchChange = useCallback(({ value }) => {
        if (value) {
            const params = {
                limit: 50,
                page: 1,
                order: "desc",
                name: value
            }
            Service.post({ url: '/activity/userandgroup/search', body: JSON.stringify(params) })
                .then((response) => {
                    const answer = response.data.filter((item) => item.type === "userName").map((item) => {
                        if (item.image === null) {
                            return { id: item.id, display: item.name, name: item.name, avatar: `${require('../../assets/img/Landing_Page/logo.png')}` }
                        } else {
                            return { id: item.id, display: item.name, name: item.name, avatar: `${Config.MEDIA_URL}${item.image}` }
                        }
                    })
                    setSuggestions(defaultSuggestionsFilter(value, answer));
                }).catch((error) => { })
        }
    }, []);

    const onSearchEditChange = useCallback(({ value }) => {
        if (value) {
            const params = {
                limit: 50,
                page: 1,
                order: "desc",
                name: value
            }
            Service.post({ url: '/activity/userandgroup/search', body: JSON.stringify(params) })
                .then((response) => {
                    const answer = response.data.filter((item) => item.type === "userName").map((item) => {
                        if (item.image === null) {
                            return { id: item.id, display: item.name, name: item.name, avatar: `${require('../../assets/img/Landing_Page/logo.png')}` }
                        } else {
                            return { id: item.id, display: item.name, name: item.name, avatar: `${Config.MEDIA_URL}${item.image}` }
                        }
                    })
                    setEditSuggestions(defaultSuggestionsFilter(value, answer));
                }).catch((error) => { })
        }
    }, []);

    // const getAllCommunity = () => {
    //     if (userData && userData.r === "n") {
    //         const params = {
    //             "limit": 1000,
    //             "page": 1,
    //             "order": "desc"
    //         }
    //         Service.post({ url: '/activity/groups/search', body: JSON.stringify(params) })
    //             .then((response) => {
    //                 const result = response.data.filter((item) => item.isFactionGroup === 0)
    //                 setCommunityData(result)
    //                 setGettingPost(result[0].id)
    //             }).catch((error) => { })
    //         // communityData && communityData.length > 0 && setGettingPost(communityData[0].id)
    //     } else {
    //         const params = {
    //             limit: 1000,
    //             page: 1
    //         }
    //         Service.post({ url: '/activity/discover/search', body: JSON.stringify(params) })
    //             .then((response) => {
    //                 const getAdminGroup = response.data.filter((item) => item.isAdmin === 1 && item.isFactionGroup === 0)
    //                 if (getAdminGroup && getAdminGroup.length > 0) {
    //                     setCommunityData1(getAdminGroup)
    //                 } else {
    //                     Service.post({ url: '/activity/groups/joinedGroup/search', body: JSON.stringify(params) })
    //                         .then((response) => {
    //                             const getAdminGroup1 = response.data.filter((item) => item.isAdmin === 1)
    //                             if (getAdminGroup1 && getAdminGroup1.length > 0) {
    //                                 setGettingPost(getAdminGroup1[0].id)
    //                                 setCommunityData1(getAdminGroup1)
    //                             }
    //                         }).catch((error) => { })
    //                 }
    //             }).catch((error) => { })
    //     }
    // }

    const getAllPosts = (item) => {
        const params = {
            limit: 50000,
            page: 1,
            order: "desc",
            groupId: paramsId.id
        }
        setPageLoading(item)
        Service.post({ url: '/activity/posts/cybotSearch', body: JSON.stringify(params) })
            .then((response) => {
                if (response.data) {
                    const result = response.data.filter((item) => item.canAccess)
                    if (result && result.length > 0) {
                        setCommunityPostData(response.data.filter((item) => item.canAccess))
                        setPageLoading(false)
                    } else {
                        if (userData && userData.r === "n") {
                            setCommunityPostData([])
                            setPageLoading(false)
                        } else {
                            setCommunityPostData(null)
                            setPageLoading(false)
                        }
                    }
                } else {
                    setCommunityPostData([])
                    setPageLoading(false)
                }
            }).catch((error) => {
                setPageLoading(false)
            })
    }

    const viewCommunityDetails = () => {
        setSectionLoading(true)
        Service.get({ url: `/activity/groups/${paramsId.id}`, body: JSON.stringify() })
            .then((response) => {
                setCommunityData(response.data)
                setSectionLoading(false)
            }).catch((error) => {
                setSectionLoading(false)
            })
    }

    const fileUploading = async (files) => {
        try {
            if (!files.name.match(/.(jpg|jpeg|png)$/i)) {
                alert('Allowed formats: JPG, JPEG and PNG')
                return false
            }
            const formData = new FormData()
            var myHeaders = new Headers();
            formData.append("file", files)

            const token = window.localStorage.getItem('token')
            if (token) {
                myHeaders.append("x-access-token", token)
            }

            const result = await fetch(`${Config.API_BASE_URL}/user/fileUpload`, {
                method: "POST",
                headers: myHeaders,
                body: formData,
                redirect: 'follow'
            })
            if (result) {
                const response = await result.json()
                setResultFiles(response.data)
            }
        } catch (e) { }
    }

    const videoToggleModal = () => {
        setValue("videolink", "")
        videoToggle()
    }

    const onVideoSubmit = (data) => {
        setVideoResultFiles(data.videolink)
        videoToggle()
    }

    const editPost = (item) => {
        editToggle();
        const blocksFromHtml = htmlToDraft(item.postMessage.split('[/1319/]')[0]);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorStates = EditorState.createWithContent(contentState);
        setEditEditorState(editorStates)
        setValue("videoMessage", item.postMessage.split('[/1319/]')[2] === "null" ? "" : item.postMessage.split('[/1319/]')[2])
        setEditIndex(item.id)
        setEditValue(item.postMessage.split('[/1319/]')[1])
    }

    const deletePost = (item) => {
        deleteToggle();
        setDeleteIndex(item)
    }

    const onAddSubmit = (data) => {
        const answer = editorState.getCurrentContent().hasText();

        if ((answer === false) && (resultVideoFiles === undefined || resultVideoFiles === "" || resultVideoFiles === null) && (resultFiles === undefined || resultFiles === "" || resultFiles === null)) {
            return false
        }

        const GroupID = paramsId.id
        const ans = stateToHTML(editorState.getCurrentContent(), options)

        const Message = resultFiles && resultVideoFiles && `${answer ? ans : ""}[/1319/]${resultFiles.filePath}[/1319/]${resultVideoFiles}` || resultFiles && `${answer ? ans : ""}[/1319/]${resultFiles.filePath}[/1319/]${null}` || resultVideoFiles && `${answer ? ans : ""}[/1319/]${null}[/1319/]${resultVideoFiles}` || `${answer ? ans : ""}[/1319/]${null}[/1319/]${null}`

        const params = {
            groupId: GroupID,
            message: Message
        }

        Service.post({ url: '/activity/posts', body: JSON.stringify(params) })
            .then((response) => {
                getAllPosts(null);
                setEditValue("")
                setValue("videolink", "")
                document.getElementById("fileInputId").value = null;
                document.getElementById("inputEditor").value = "";
                setResultFiles("")
                setVideoResultFiles("")
                setEditorState(() => EditorState.createEmpty())
                setSuggestions([])
            }).catch((error) => { })
    }

    const onEditSubmit = (data) => {

        const answer = editEditorState.getCurrentContent().hasText();
        if ((answer === undefined || answer === "" || answer === null) && (data.videoMessage === undefined || data.videoMessage === "" || data.videoMessage === null) && (resultFiles === undefined || resultFiles === "" || resultFiles === null) && (editValue === undefined || editValue === "" || editValue === null || editValue === "null")) {
            return false
        }
        const ans = stateToHTML(editEditorState.getCurrentContent(), options)
        const Message = `${answer && answer ? ans : ""}[/1319/]${resultFiles && resultFiles.filePath ? resultFiles.filePath : editValue}[/1319/]${data.videoMessage ? data.videoMessage : null}`
        const params = {
            message: Message
        }
        Service.patch({ url: `/activity/posts/${editIndex}`, body: JSON.stringify(params) })
            .then((response) => {
                editToggle();
                getAllPosts(null);
                setResultFiles("")
                setVideoResultFiles("")
                setEditValue("")
                setValue("videolink", "")
                document.getElementById("fileInput1").value = null;
                document.getElementById("editInputEditor").value = "";
                setEditEditorState(() => EditorState.createEmpty())
            }).catch((error) => { })
    }

    const onDeleteSubmit = () => {
        Service.delete({ url: `/activity/posts/${deleteIndex.id}`, body: JSON.stringify() })
            .then((response) => {
                deleteToggle();
                getAllPosts(null);
            }).catch((error) => { })
    }

    function navigate(event) {
        event.preventDefault()
        if (event.target.tagName === 'A') {
            history.push(event.target.getAttribute('href'))
        }
    }

    const userProfile = (item) => {
        if (item.user === userData.id) {
            history.push(`/cybot`)
        } else {
            history.push(`/home/${item.user}`)
        }
    }

    return (
        <>
            <div className="px-4 bg-right">
                <SearchInput />
                <Row>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="mt-4">
                        <div className="mx-sm-5 px-sm-5 mx-lg-0 px-lg-0">
                            <Menu />
                        </div>
                        <div className="d-none d-lg-block mt-lg-4 stickey">
                            <MenuItems />
                        </div>
                    </Col>
                    <Col xl="6" lg="6" md="12" sm="12" xs="12" >
                        {
                            pageLoading && sectionLoading ? <div className="bg-primary2 p-3 m-3 text-center"><Spinner color="light" /></div> :
                                <div className="middle-info-bg h-lg-100 p-2 p-xl-4 mt-4">
                                    <div className="bg-primary m-2 m-sm-3">
                                        <img src={communityData.GroupImage ? `${Config.MEDIA_URL}${communityData.GroupImage}` : require('../../assets/img/Landing_Page/backimage.jpg').default} height={'fit-content'} width={'100%'} alt="" />
                                        <div className="d-flex flex-column flex-sm-row justify-content-between mx-4 py-4">
                                            <div>
                                                <h3 className="mb-1 text-center text-sm-left">{communityData.GroupName}</h3>
                                                <div>
                                                    <p className="text-sm-left text-center">{communityData.asGroupDescription}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                                                <div className="text-center mr-3">
                                                    <p>Members -<span className="text-white">{communityData.count}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {
                                userData && userData.r === "n" ? communityData && communityData.length > 0 && communityData.map((item) => {
                                    return <div className="bg-primary m-3">
                                        <img src={item.GroupImage ? `${Config.MEDIA_URL}${item.GroupImage}` : require('../../assets/img/Landing_Page/backimage.jpg').default} height={'fit-content'} width={'100%'} alt="" />
                                        <div className="d-flex flex-column flex-sm-row justify-content-between mx-2 py-4">
                                            <div>
                                                <h3 className="mb-1 text-center text-sm-left">{item.GroupName}</h3>
                                                <div>
                                                    <p className="text-center text-sm-left">{item.GroupDescription}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                                                <div className="text-center mr-3">
                                                    <p>Members -<span className="text-white">{item.membersCount}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }) : communityData1 && communityData1.length > 0 && communityData1.map((item) => {
                                    return <div className="bg-primary m-3">
                                        <img src={(item.GroupImage ? item.GroupImage : item.image) ? `${Config.MEDIA_URL}${item.GroupImage ? item.GroupImage : item.image}` : require('../../assets/img/Landing_Page/backimage.jpg').default} height={'fit-content'} width={'100%'} alt="" />
                                        <div className="d-flex flex-column flex-sm-row justify-content-between mx-2 py-4">
                                            <div>
                                                <h3 className="mb-1 text-center text-sm-left">{item.GroupName ? item.GroupName : item.name}</h3>
                                                <div>
                                                    <p className="text-center text-sm-left">{item.GroupDescription ? item.GroupDescription : item.description}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                                                <div className="text-center mr-3">
                                                    <p>Members -<span className="text-white">{item.membersCount}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            } */}

                                    {
                                        userData.r === "n" && userData.role === "superadmin" ? <div className="bg-primary m-2 m-sm-3">
                                            <Form onSubmit={handleSubmit(onAddSubmit)}>
                                                <Row className="d-flex align-items-center justify-content-center p-3">
                                                    <Col xl="12" lg="12" md="12" sm="12">
                                                        <Row className="d-flex flex-row justify-content-center align-items-center">
                                                            <Col xl="2" lg="2" md="2" sm="2" xs="12">
                                                                <div className="avatar-img1">
                                                                    <img src={userData.avatar ? `${Config.MEDIA_URL}${userData.avatar}` : require('../../assets/img/Landing_Page/logo.png')} className="avatar-imginside" alt="" width={"90%"} height="90%" />
                                                                </div>
                                                            </Col>
                                                            <Col xl="10" lg="10" md="10" sm="10" xs="12">
                                                                <div
                                                                    id="inputEditor"
                                                                    className='editor ml-sm-2 ml-lg-0 ml-xl-2 mb-0'
                                                                    onClick={() => {
                                                                        ref.current.focus();
                                                                    }}
                                                                >
                                                                    <Editor
                                                                        editorKey={'editor'}
                                                                        editorState={editorState}
                                                                        onChange={setEditorState}
                                                                        plugins={plugins}
                                                                        ref={ref}
                                                                        placeholder="Start post to your profile"
                                                                    />

                                                                    <MentionSuggestions
                                                                        open={open}
                                                                        onOpenChange={onOpenChange}
                                                                        suggestions={suggestions}
                                                                        onSearchChange={onSearchChange}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="12" md="12" sm="12" xs='12'>
                                                        <div className="ml-sm-5 mt-2 row flex-wrap align-items-center justify-content-center main-input-image">
                                                            <div className="col-sm-4 col-6 justify-content-center image-video-input d-flex align-items-center">
                                                                <img src={require('../../assets/img/Landing_Page/image.png')} /> <span className="text-secondary pl-2">Image</span>
                                                                <Input id="fileInputId" name="fileUpload" className="avatarinput2 cursor-pointer" type="file" onChange={(e) => fileUploading(e.target.files[0])} />
                                                            </div>
                                                            <div className="col-sm-4 col-6 justify-content-sm-start justify-content-center d-flex align-items-center" onClick={() => videoToggleModal()}>
                                                                <div className="cursor-pointer">
                                                                <img src={require('../../assets/img/Landing_Page/video-play.png')} /> <span className="text-secondary pl-2">Video</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4 col-12 text-sm-left text-center mt-3 mt-md-0">
                                                                <Button className="modal-btn"><h5 className="mb-0">Post</h5></Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div> : <></>
                                    }

                                    {
                                        pageLoading ? <div className="bg-primary p-3 m-2 m-sm-3 text-center"><Spinner color="light" /></div> :
                                            communityPostData && communityPostData.length > 0 && communityPostData.map((item, index) => {
                                                return <div className="bg-primary m-2 m-sm-3 pb-1" id={item.id}>
                                                    <div className="d-flex align-items-center justify-content-between py-3 pl-3 pr-3">
                                                        <div className="d-flex align-items-center justify-content-start cursor-pointer" onClick={() => userProfile(item)}>
                                                            <div className="avatar-img1">
                                                                <img src={item.UserProfileImage ? `${Config.MEDIA_URL}${item.UserProfileImage}` : require('../../assets/img/Landing_Page/logo.png')} className="avatar-imginside" alt="" width={"90%"} height="90%" />
                                                            </div>
                                                            <div className="ml-2">
                                                                <h4 className="mb-0">{item.userName ? item.userName : "Bot"}</h4>
                                                                <span>Posted at {moment(item.date).format('LLL')}</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-row align-items-center justify-content-start">
                                                            <div className="mb-3">
                                                                {item.isPin > 0 && <AiOutlinePushpin color="#FFF" size={30} />}
                                                            </div>
                                                            <UncontrolledDropdown className='dropdown-user nav-item'>
                                                                <DropdownToggle href='/' tag='a' className='align-items-center' onClick={e => e.preventDefault()} >
                                                                    {
                                                                        userData.r === "n" && userData.role === "superadmin" ? <img src={require('../../assets/img/Landing_Page/menu1.png')} className="mr-3 mb-3" alt="" /> : <></>
                                                                    }
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-right optionpost">
                                                                    <DropdownItem id={index} key={index} to='#' onClick={() => editPost(item)}>
                                                                        <span className='align-middle optionpostitem'>Edit Post</span>
                                                                    </DropdownItem>
                                                                    <DropdownItem to='#' onClick={() => deletePost(item)}>
                                                                        <span className='align-middle optionpostitem'>Delete Post</span>
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </div>
                                                    </div>
                                                    <p onClick={(e) => navigate(e)} dangerouslySetInnerHTML={{ __html: item.postMessage.split("[/1319/]")[0] }} className="mx-4 mb-4 text-capitalize text-justify"></p>
                                                    {
                                                        item.postMessage.split("[/1319/]")[1] === "null" || item.postMessage.split("[/1319/]")[1] === undefined ? <></> : <img src={`${Config.MEDIA_URL}${item.postMessage.split("[/1319/]")[1]}`} className="px-3 pb-3 image-width" height={'fit-content'} alt="" />
                                                    }
                                                    {
                                                        item.postMessage.split("[/1319/]")[2] === "null" || item.postMessage.split("[/1319/]")[2] === undefined ? <></> : <ReactPlayer className="px-3 image-width rounded" url={item.postMessage.split("[/1319/]")[2]} controls={true} width={"100%"} />
                                                    }
                                                </div>
                                            }) || communityPostData === null && communityData && communityData.canAccess === 0 && <div className="bg-primary1 p-3 m-3 text-center"><h6>Join this Community from discover to get updates...</h6>
                                                <Button className="modal-btn" onClick={() => history.push("/discover")}><h6 className="mb-0">Go to Discover</h6></Button></div>
                                            || <div className="bg-primary p-3 m-3 text-center">
                                                <h6 className="mb-0">No Posts yet</h6>
                                            </div>
                                    }

                                    {/* <------- Edit Modal ------------> */}

                                    <Modal isOpen={editModal} toggle={editToggle}>
                                        <ModalHeader toggle={editToggle}>Edit Social Post</ModalHeader>
                                        <hr className="w-100 modal-line" />
                                        <ModalBody>
                                            <Form onSubmit={handleSubmit(onEditSubmit)}>
                                                <FormGroup>
                                                    <Label for="message">
                                                        Message
                                                    </Label>
                                                    <div
                                                        id="editInputEditor"
                                                        className='editor ml-sm-2 ml-lg-0 ml-xl-2 mb-0'
                                                        onClick={() => {
                                                            editref.current.focus();
                                                        }}
                                                    >
                                                        <Editor
                                                            editorKey={'editor'}
                                                            editorState={editEditorState}
                                                            onChange={setEditEditorState}
                                                            plugins={plugins1}
                                                            ref={editref}
                                                        />
                                                        <MentionSuggestions2
                                                            open={editOpen}
                                                            onOpenChange={onEditOpenChange}
                                                            suggestions={editSuggestions}
                                                            onSearchChange={onSearchEditChange}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="photoMessage" >
                                                        Photo
                                                    </Label><br />
                                                    <Input id="fileInput1" name="fileUpload" className="avatarinput3 mt-4 pt-1" type="file" onChange={(e) => fileUploading(e.target.files[0])} />
                                                    {editValue === "null" ? <></> : <img className="mt-4" src={`${Config.MEDIA_URL}${editValue}`} alt="" width={"100px"} height={"50px"}></img>}
                                                </FormGroup>
                                                <FormGroup className="mt-4">
                                                    <Label for="videoMessage">
                                                        Video
                                                    </Label>
                                                    <Controller
                                                        name="videoMessage"
                                                        control={control}
                                                        id="videoMessage"
                                                        render={({ field }) => <Input placeholder="Enter Video Link" type="text" {...field} />}
                                                    />
                                                </FormGroup>
                                                <ModalFooter>
                                                    <Button color="success" type="submit">
                                                        Edit
                                                    </Button>{' '}
                                                    <Button color="secondary" onClick={editToggle}>
                                                        Cancel
                                                    </Button>
                                                </ModalFooter>
                                            </Form>
                                        </ModalBody>
                                    </Modal>

                                    {/* <Modal isOpen={editModal} toggle={editToggle}>
                                <ModalHeader toggle={editToggle}>Edit Social Post</ModalHeader>
                                <hr className="w-100 modal-line" />
                                <ModalBody>
                                    <Form onSubmit={handleSubmit(onEditSubmit)}>
                                        <FormGroup>
                                            <Label for="message">
                                                Message
                                            </Label>
                                            <Controller
                                                name="editMessage"
                                                control={control}
                                                id="message"
                                                render={({ field }) => <Input className="textareacontainer" placeholder="Enter Message" type="textarea" {...field} />}
                                            />
                                        </FormGroup>
                                        <ModalFooter>
                                            <Button color="success" type="submit">
                                                Edit
                                            </Button>{' '}
                                            <Button color="secondary" onClick={editToggle}>
                                                Cancel
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                </ModalBody>
                            </Modal> */}

                                    {/* <------- Delete Modal ------------> */}

                                    <Modal isOpen={deleteModal} toggle={deleteToggle}>
                                        <ModalHeader toggle={deleteToggle}>Delete Social Post</ModalHeader>
                                        <hr className="w-100 modal-line" />
                                        <ModalBody>
                                            <span>Do you really want to delete the post ?</span>
                                            <ModalFooter>
                                                <Button color="success" type="submit" onClick={() => onDeleteSubmit()}>
                                                    Delete
                                                </Button>{' '}
                                                <Button color="secondary" onClick={deleteToggle}>
                                                    Cancel
                                                </Button>
                                            </ModalFooter>
                                        </ModalBody>
                                    </Modal>

                                    {/* <------- Video Upload Modal ------------> */}

                                    <Modal isOpen={videoModal} toggle={videoToggle}>
                                        <ModalHeader toggle={videoToggle}>Video</ModalHeader>
                                        <hr className="w-100 modal-line" />
                                        <ModalBody>
                                            <Form onSubmit={handleSubmit(onVideoSubmit)}>
                                                <FormGroup>
                                                    <Label for="videolink">
                                                        Enter Video Link
                                                    </Label>
                                                    <Controller
                                                        name="videolink"
                                                        control={control}
                                                        id="videolink"
                                                        render={({ field }) => <Input placeholder="Enter Video Link" type="text" {...field} />}
                                                    />
                                                </FormGroup>
                                                <ModalFooter>
                                                    <Button color="success" type="submit">
                                                        Add
                                                    </Button>{' '}
                                                    <Button color="secondary" onClick={videoToggle}>
                                                        Cancel
                                                    </Button>
                                                </ModalFooter>
                                            </Form>
                                        </ModalBody>
                                    </Modal>
                                </div>
                        }
                    </Col>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="p-0 my-5 mt-sm-0">
                        <Likes />
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default CybotProfile
