import React, { useState, useEffect } from "react";
import { X } from 'react-feather'
import { HiOutlineMenuAlt1 } from "react-icons/hi"
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Collapse, Nav, NavItem } from "reactstrap"
import { Storage } from "services/service";
import { RiHome5Line } from 'react-icons/ri'
import { FaRobot } from 'react-icons/fa'
import { AiOutlineNotification } from 'react-icons/ai'
import { MdGroups, MdOutlineAdminPanelSettings } from 'react-icons/md'
import { SiCrowdsource } from 'react-icons/si'
import { RiFunctionLine } from 'react-icons/ri'
import { CgProfile } from 'react-icons/cg'
import { cybotId } from "./Likes";

import { useAtom } from 'jotai'

export const MenuItems = () => {

    let userData = Storage.get("userData")

    const [innerCybotId] = useAtom(cybotId);

    const history = useHistory()
    const [collapse, setCollapse] = useState(false)

    const toggleCollapse = () => setCollapse(!collapse)

    useEffect(() => {
        document.body.classList.remove('navbar-open');
        if (localStorage.getItem('token') === null) {
            history.push('/')
        }
    }, [])
    const onCollapseEntered = () => {
        document.body.classList.add('navbar-open');
    };
    const onCollapseExited = () => {
        document.body.classList.remove('navbar-open');
    };

    return (
        <>
            <Row>
                <Col xl="12" lg="12">
                    <Nav vertical className="sidebar-nav mt-3">
                        <div className='d-block d-lg-none'>
                            <HiOutlineMenuAlt1 onClick={toggleCollapse} color="white" className='cursor-pointer d-block d-xl-none' size={25} />
                            <Collapse
                                navbar
                                isOpen={collapse}
                                onEntered={onCollapseEntered}
                                onExited={onCollapseExited}
                            >
                                <div className='d-flex justify-content-between'>
                                    <div className="menu-items">
                                        <NavItem>
                                            <Link className={`d-flex my-2 my-xl-4 align-items-center p-3 ${(window.location.href.indexOf('/babble')) > -1 && 'menu-active rounded-lg'}`} to='/babble'>
                                                <AiOutlineNotification size={"30px"} color="white" className="mr-4" />
                                                <h4 className="mb-0">Babble</h4>
                                            </Link>
                                        </NavItem>
                                        {
                                            userData.r === "r" ? <NavItem>
                                                <Link className={`d-flex mt-xl-4 p-3 align-items-center ${(window.location.href.indexOf('/home')) > -1 && 'menu-active rounded-lg'}`} to='/home'>
                                                    <CgProfile color="white" className="mr-4" size={"2rem"} />
                                                    <h4 className="mb-0">Profile</h4>
                                                </Link>
                                            </NavItem> : <></>
                                        }
                                        <NavItem>
                                            <Link className={`d-flex mt-xl-4 p-3 align-items-center ${(window.location.href.indexOf('/cybot')) > -1 && 'menu-active rounded-lg'}`} to={'/cybot' + (innerCybotId != -1 ? ('/' + innerCybotId) : '')}>
                                                <FaRobot size={"30px"} color="white" className="mr-4" />
                                                <h4 className="mb-0">Cybot</h4>
                                            </Link>
                                        </NavItem>
                                       
                                        <NavItem>
                                            <Link className={`d-flex my-2 my-xl-4 p-3 align-items-center ${(window.location.href.indexOf('/discover')) > -1 && 'menu-active rounded-lg'}`} to='/discover'>
                                                <MdGroups size={"30px"} color="white" className="mr-4" />
                                                <h4 className="mb-0">Discover</h4>
                                            </Link>
                                        </NavItem>
                                        {/* <NavItem>
                                            <Link className={`d-flex my-2 my-xl-4 p-3 ${(window.location.href.indexOf('/community')) > -1 && 'menu-active rounded-lg'}`} to='/community'>
                                                <SiCrowdsource size={"30px"} color="white" className="mr-4" />
                                                <h4 className="mb-0">Community</h4>
                                            </Link>
                                        </NavItem> */}
                                        {/* <NavItem>
                                            <Link className={`d-flex my-2 my-xl-4 p-3 ${(window.location.href.indexOf('/babble')) > -1 && 'menu-active rounded-lg'}`} to='/babble'>
                                                <img src={require('../assets/img/Landing_Page/people.png')} width={20} height={20} className="mt-2 mr-4" />
                                                <h4 className="mb-0">Babble</h4>
                                            </Link>
                                        </NavItem> */}
                                        <NavItem>
                                            <Link className={`d-flex my-2 my-xl-3 p-3 align-items-center ${(window.location.href.indexOf('/faction')) > -1 && 'menu-active rounded-lg'}`} to='/faction'>
                                                <RiFunctionLine size={"30px"} color="white" className="mr-4" />
                                                <h4 className="mb-0">Faction</h4>
                                            </Link>
                                        </NavItem>
                                        {
                                            userData.r === "n" ? <NavItem>
                                                <Link className={`d-flex my-2 my-xl-4 p-3 align-items-center ${(window.location.href.indexOf('/admin')) > -1 && 'menu-active rounded-lg'}`} to='/admin'>
                                                    <MdOutlineAdminPanelSettings size={"30px"} color="white" className="mr-4" />
                                                    <h4 className="mb-0">Admin</h4>
                                                </Link>
                                            </NavItem> : <></>
                                        }
                                    </div>
                                    <X color='white' className='cursor-pointer' onClick={toggleCollapse} />
                                </div>
                            </Collapse>
                        </div>
                        <div className='d-none d-lg-block'>
                            <NavItem className={(window.location.href.indexOf('/babble')) > -1 && 'menu-active rounded-lg d-block'}>
                                <Link className={`d-flex my-2 my-xl-4 p-3 ${(window.location.href.indexOf('/babble')) > -1 && 'menu-active rounded-lg'}`} to='/babble'>
                                    <AiOutlineNotification size={"30px"} color="white" className="mr-4" />
                                    <h4 className="mb-0">Babble</h4>
                                </Link>
                            </NavItem>
                            {
                                userData.r === "r" ? <NavItem className={(window.location.href.indexOf('/home')) > -1 && 'menu-active rounded-lg d-block'}>
                                    <Link className={`d-flex mt-xl-4 p-3 ${(window.location.href.indexOf('/home')) > -1 && 'menu-active rounded-lg'}`} to='/home'>
                                        <CgProfile color="white" size={"30px"} className={`mr-4 text-secondary${(window.location.href.indexOf('/home')) > -1 && 'mr-4 icon-color'}`} />
                                        <h4 className="mb-0">Profile</h4>
                                    </Link>
                                </NavItem> : <></>
                            }
                            <NavItem className={(window.location.href.indexOf('/cybot')) > -1 && 'menu-active rounded-lg d-block'}>
                                <Link className={`d-flex mt-xl-4 p-3 ${(window.location.href.indexOf('/cybot')) > -1 && 'menu-active rounded-lg'}`} to={'/cybot' + (innerCybotId != -1 ? ('/' + innerCybotId) : '')}>
                                    <FaRobot size={"30px"} color="white" className="mr-4" />
                                    <h4 className="mb-0">Cybot</h4>
                                </Link>
                            </NavItem>
                            <NavItem className={(window.location.href.indexOf('/discover')) > -1 && 'menu-active rounded-lg d-block'}>
                                <Link className={`d-flex my-2 my-xl-4 p-3 ${(window.location.href.indexOf('/discover')) > -1 && 'menu-active rounded-lg'}`} to='/discover'>
                                    <MdGroups size={"30px"} color="white" className="mr-4" />
                                    <h4 className="mb-0">Discover</h4>
                                </Link>
                            </NavItem>
                            {/* <NavItem className={(window.location.href.indexOf('/community')) > -1 && 'menu-active rounded-lg d-block'}>
                                <Link className={`d-flex my-2 my-xl-4 p-3 ${(window.location.href.indexOf('/community')) > -1 && 'menu-active rounded-lg'}`} to='/community'>
                                    <SiCrowdsource size={"30px"} color="white" className="mr-4" />
                                    <h4 className="mb-0">Community</h4>
                                </Link>
                            </NavItem> */}
                            {/* <NavItem className={(window.location.href.indexOf('/babble')) > -1 && 'menu-active rounded-lg d-block'}>
                                <Link className={`d-flex my-2 my-xl-4 p-3 ${(window.location.href.indexOf('/babble')) > -1 && 'menu-active rounded-lg'}`} to='/babble'>
                                    <img src={require('../assets/img/Landing_Page/people.png')} width={20} height={20} className="mt-2 mr-4" />
                                    <h4 className="mb-0">Babble</h4>
                                </Link>
                            </NavItem> */}
                            <NavItem className={(window.location.href.indexOf('/faction')) > -1 && 'menu-active rounded-lg d-block'}>
                                <Link className={`d-flex my-2 my-xl-3 p-3 ${(window.location.href.indexOf('/faction/1')) > -1 && 'menu-active rounded-lg'}`} to="/faction">
                                    <RiFunctionLine size={"30px"} color="white" className="mr-4" />
                                    <h4 className="mb-0">Faction</h4>
                                </Link>
                            </NavItem>
                            {
                                userData.r === "n" ? <NavItem className={`${(window.location.href.indexOf('/admin')) > -1 && 'menu-active rounded-lg d-block'}`}>
                                    <Link className={`d-flex my-2 my-xl-4 p-3 ${(window.location.href.indexOf('/admin')) > -1 && 'menu-active rounded-lg'}`} to='/admin'>
                                        <MdOutlineAdminPanelSettings size={"30px"} color="white" className="mr-4" />
                                        <h4 className="mb-0">Admin</h4>
                                    </Link>
                                </NavItem> : <></>
                            }
                        </div>
                    </Nav>

                </Col>
            </Row>
        </>
    )
}




