
import React from "react";
// core components
import Banner from "./Banner";
import 'bootstrap/dist/css/bootstrap.css';


export default function Index() {
  React.useEffect(() => {
    document.body.classList.toggle("index-page");
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };

  }, []);
  return (
    <>
      <div>
        <Banner />
      </div>
    </>
  );
}
