import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { Input, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, PopoverBody, ListGroup, ListGroupItem, UncontrolledPopover } from "reactstrap"
import { Search } from 'react-feather'
import { MenuItems } from "./MenuItems";
import { Service } from "services/service";
import { Link, useHistory } from "react-router-dom";
import { Storage } from "services/service";
import { IoMdNotificationsOutline } from "react-icons/io"
import { RiMessage2Line } from 'react-icons/ri'
import Config from '../config/Config.json'
import Avatar from "react-avatar";
import io from 'socket.io-client';
const socket = io(Config.API_BASE_URL); // Replace with your backend URL

export const SearchInput = ({ refresh }) => {

    const history = useHistory()
    const [factionData, setFactionData] = useState([])
    let userData = Storage.get("userData")

    const [search, setSearch] = useState("")
    const [data, setData] = useState([])
    const [notificationData, setNotificationData] = useState([])
    const [displaySearch, setDisplaySearch] = useState(false)
    const [dynamicNotification, setDynamicNotification] = useState([]);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
      // Listen for notifications
      console.log('notificationuseEffect');
      socket.on('notification', (data) => {
        if (!isConnected) {
            setIsConnected(true);
            console.log("callnowinsideon")
            if (!dynamicNotification.includes(data.id) && (userData.id !== data.sender) && (data.user !== data.sender)) {
                setDynamicNotification( [...dynamicNotification, data.id] )        
                // setNotification(data);
                showToastMessageSuccess("Notification received")
                if (!notificationData.list.find( d => d.id == data.id)) {
                    const newData = {
                        unread: (notificationData.unread || 0) + 1,
                        list: [...notificationData.list, data]
                    }
                    setNotificationData(newData)
                }
            }    
        }
      });
  
      return () => {
        // Clean up event listener
        socket.off('notification');
        setIsConnected(false);
      };
    });

    useEffect(() => {
        getAllNotifications();
    }, [refresh && refresh])

    const showToastMessageSuccess = (info) => {
        toast.success(info, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    useEffect(() => {
        viewFactionDetails();
    }, [])


    const getAllNotifications = () => {
        Service.post({ url: '/activity/notification/search', body: JSON.stringify() })
            .then((response) => {
                setNotificationData(response.data)
            }).catch((error) => { })
    }

    const viewFactionDetails = () => {
        Service.get({ url: `/activity/groups/factionGroup/${userData.faction}`, body: JSON.stringify() })
            .then((response) => {
                setFactionData(response.data)
            }).catch((error) => { })
    }

    const onSearching = (e) => {
        setDisplaySearch(false)
        if (e.target.value === "") {
            setSearch("")
            setData([])
            return
        }
        setSearch(e.target.value)
        const params = {
            limit: 1000,
            page: 1,
            order: "desc",
            name: e.target.value
        }

        Service.post({ url: '/activity/userandgroup/search', body: JSON.stringify(params) })
            .then((response) => {
                setData(response.data)
            }).catch((error) => { })
    }

    const viewNotification = (item) => {
        Service.get({ url: `/activity/notification/${item}`, body: JSON.stringify() })
            .then((response) => {
                getAllNotifications();
            }).catch((error) => { })
    }


    const logout = () => {
        localStorage.clear()
        window.location.href = "/";
    }

    return (
            <Row className="align-items-center justify-content-center">
                <Col xl="3" lg="3" md="12" sm="12" xs="12" className="d-flex justify-content-between align-items-center">
                    <img className="cursor-pointer outrider-logo" src={require('../assets/img/Landing_Page/logo.png')} onClick={() => history.push("/home")} />
                    <div className="d-block d-lg-none">
                        <MenuItems />
                    </div>
                </Col>
                <Col xl="6" lg="5" md="6" sm="12" xs="12" className="px-xl-5 mb-md-0 mb-3">
                    <Input
                        placeholder='Find friends, communities, or group here'
                        id='search-invoice'
                        value={search}
                        onChange={(e) => onSearching(e)}
                        type='text'
                        className="search-box1 text-white pl-5 py-4"
                    />
                    <div className={displaySearch ? "resultBox d-none" : "resultBox"}>
                        <ListGroup className="parentlist">
                            <div className="scrolling-enable">
                                {
                                    (data && data.length > 0) ? data.map((item, index) => {

                                        if (item.type === "groupName") {
                                            return <Link key={item.id} to={`/community/${item.id}`}>
                                                <ListGroupItem className="listitemstyle" onClick={() => { setDisplaySearch(true); setSearch("") }}>
                                                    <Row className="d-flex align-items-center p-3 avtar-img-search">
                                                        <Col lg="3" md="3" sm="4" xs="4" className="text-center text-sm-left">
                                                            {
                                                                item.image ? <img src={item.image && `${Config.MEDIA_URL}${item.image}`} alt="avatar" width={"50%"} height="50%" /> : <Avatar name={item.name.split("").splice(0, 1).join("")} size="45" colors={['#e14eca', '#00f2c3']} />
                                                            }
                                                        </Col>
                                                        <Col lg="9" md="9" sm="8" xs="8" className="mt-3 mt-sm-0">
                                                            <h5 className="mb-1">{item.name}</h5>
                                                            <div className="d-flex">
                                                                <h6 className="mt-1">View Group</h6>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </ListGroupItem>
                                            </Link>
                                        }

                                        if (item.type === "userName") {
                                            return <Link to={`/home/${item.id}`}>
                                                <ListGroupItem className="listitemstyle" onClick={() => { setDisplaySearch(true); setSearch("") }}>
                                                    <Row className="d-flex align-items-center p-3 avtar-img-search">
                                                        <Col lg="3" md="3" sm="4" xs="4" className="text-center text-sm-left">
                                                            {
                                                                item.image ? <img src={item.image && `${Config.MEDIA_URL}${item.image}`} alt="avatar" width={"50%"} height="50%" /> : <Avatar name={item.name.split("").splice(0, 1).join("")} size="45" colors={['#e14eca', '#00f2c3']} />
                                                            }
                                                        </Col>
                                                        <Col lg="9" md="9" sm="8" xs="8" className="mt-3 mt-sm-0">
                                                            <h5 className="mb-1">{item.name}</h5>
                                                            <div className="d-flex">
                                                                <h6 className="mt-1">View Profile</h6>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </ListGroupItem>
                                            </Link>
                                        }
                                    }) : <span className="d-none">Empty</span>
                                }
                            </div>
                        </ListGroup>
                    </div>
                    <Search color="whitesmoke" className="position-abs2 ml-3 pl-1" />
                </Col>
                <Col xl="3" lg="4" md="6" sm="8" xs="12">
                    <div className="d-flex align-items-center justify-content-around">
                        {/* <div>
                            <RiMessage2Line size={"25px"} className="notification-edit-icon mt-2" />
                        </div> */}
                        <div>
                            <IoMdNotificationsOutline id="PopoverLegacy1" size={"25px"} className="notification-edit-icon mt-2 cursor-pointer" />
                            <UncontrolledPopover
                                placement="bottom"
                                target="PopoverLegacy1"
                                trigger="legacy"
                            >
                                <div className="scrolling-enable2 popover-notification">
                                    <PopoverBody>
                                        {
                                            notificationData && notificationData.list && notificationData.list.length > 0 ? notificationData.list.map((item) => (
                                                <>
                                                    {
                                                        item.type === "group" ? <></> : <>
                                                            {
                                                                item.groupId === 0 && userData.r === "r" && <Link to={`/home/#${item.postId}`} onClick={() => viewNotification(item.id)}>
                                                                    <div className="bg-secondary border-rad p-1 mb-3">
                                                                        <Row>
                                                                            <Col xl="4" lg="12" xs="12" md="12" className="d-flex justify-content-center feed-border-right pr-0 pl-1">
                                                                                <div className={item.SenderProfileImage ? "avatar-img3" : "avatar-img3-original"}>
                                                                                    <img src={item.SenderProfileImage ? `${Config.MEDIA_URL}${item.SenderProfileImage}` : require('../assets/img/Landing_Page/logo.png')} alt="avatar" className="avatar-imginside"
                                                                                        width={"80%"} height="80%" />
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl="8" lg="12" md="12" xs="12">
                                                                                <div className="d-flex flex-column flex-sm-row flex-lg-column flex-xl-row justify-content-center justify-content-xl-start align-items-center">
                                                                                    <p className="d-inline noti-name-color"><p className="noti-name-color d-inline">{item.SenderName ? item.SenderName : `User ${item.sender}`}</p> has <p className="d-inline noti-name-color">{item.type === "comment" && "Commented on your Post" || item.type === "p_Like" && "Liked your Post" || item.type === "p_dislike" && "Disliked your Post" || item.type === "c_Like" && "Liked your Comment" || item.type === "post" && "Posted on your Community"}</p></p>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Link> || item.groupId === factionData.id && userData.r === "r" && <Link to={`/faction/#${item.postId}`} onClick={() => viewNotification(item.id)}>
                                                                    <div className="bg-secondary border-rad p-1 mb-3">
                                                                        <Row>
                                                                            <Col xl="4" lg="12" xs="12" md="12" className="d-flex justify-content-center feed-border-right pr-0 pl-1">
                                                                                <div className={item.SenderProfileImage ? "avatar-img3" : "avatar-img3-original"}>
                                                                                    <img src={item.SenderProfileImage ? `${Config.MEDIA_URL}${item.SenderProfileImage}` : require('../assets/img/Landing_Page/logo.png')} alt="avatar" className="avatar-imginside"
                                                                                        width={"80%"} height="80%" />
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl="8" lg="12" md="12" xs="12">
                                                                                <div className="d-flex flex-column flex-sm-row flex-lg-column flex-xl-row justify-content-center justify-content-xl-start align-items-center">
                                                                                    <p className="d-inline noti-name-color"><p className="noti-name-color d-inline">{item.SenderName ? item.SenderName : `User ${item.sender}`}</p> has <p className="d-inline noti-name-color">{item.type === "comment" && "Commented on your Post" || item.type === "p_Like" && "Liked your Post" || item.type === "p_dislike" && "Disliked your Post" || item.type === "c_Like" && "Liked your Comment" || item.type === "post" && "Posted on your Community"}</p></p>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Link> || <Link to={`/community/${item.groupId}/#${item.postId}`} onClick={() => viewNotification(item.id)}>
                                                                    <div className="bg-secondary border-rad p-1 mb-3">
                                                                        <Row>
                                                                            <Col xl="4" lg="12" xs="12" md="12" className="d-flex justify-content-center feed-border-right pr-0 pl-1">
                                                                                <div className={item.SenderProfileImage ? "avatar-img3" : "avatar-img3-original"}>
                                                                                    <img src={item.SenderProfileImage ? `${Config.MEDIA_URL}${item.SenderProfileImage}` : require('../assets/img/Landing_Page/logo.png')} alt="avatar" className="avatar-imginside"
                                                                                        width={"80%"} height="80%" />
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl="8" lg="12" md="12" xs="12">
                                                                                <div className="d-flex flex-column flex-sm-row flex-lg-column flex-xl-row justify-content-center justify-content-xl-start align-items-center">
                                                                                    <p className="d-inline noti-name-color"><p className="noti-name-color d-inline">{item.SenderName ? item.SenderName : `User ${item.sender}`}</p> has <p className="d-inline noti-name-color">{item.type === "comment" && "Commented on your Post" || item.type === "p_Like" && "Liked your Post" || item.type === "p_dislike" && "Disliked your Post" || item.type === "c_Like" && "Liked your Comment" || item.type === "post" && "Posted on your Community"}</p></p>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Link>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            )) : <span>Empty</span>
                                        }
                                    </PopoverBody>
                                </div>
                            </UncontrolledPopover>
                            <small className="number2">{notificationData.unread}</small>
                        </div>
                        <div>
                            <span>
                                <h5 className="mb-1">{userData.name}</h5>
                                <p>@{userData.email}</p>
                            </span>
                        </div>
                        <UncontrolledDropdown className='dropdown-user nav-item'>
                            <DropdownToggle href='/' tag='a' className='align-items-center' onClick={e => e.preventDefault()} >
                                <div className={userData.avatar ? "avatar-img1" : "avatar-img1-original"}>
                                    <img src={userData.avatar ? `${Config.MEDIA_URL}${userData.avatar}` : require('../assets/img/Landing_Page/logo.png')} width={"90%"} height="90%"
                                        className="avatar-imginside" />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-right optionpost">
                                <DropdownItem to='#' onClick={() => { logout() }}  >
                                    <span className='align-middle optionpostitem'>Logout</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </Col>
            </Row>
    )
}