import React, { useEffect, useState } from "react";
import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Button, Input, ModalFooter, Tooltip, Spinner } from "reactstrap"
import { Menu } from "components/menu";
import { Likes } from "components/Likes";
import { SearchInput } from "components/search";
import { MenuItems } from "components/MenuItems";
import { AnchorScroll } from "helper";
import Config from '../../config/Config';
import { Service, Storage } from "services/service";
import { useParams, useHistory } from "react-router-dom";
import { AiOutlineLike, AiFillLike, AiFillDislike, AiOutlineDislike, AiOutlinePushpin } from 'react-icons/ai';
import { BiCommentMinus } from 'react-icons/bi'
import { TbReportSearch } from 'react-icons/tb'
import { Controller, useForm } from "react-hook-form";
import ReactPlayer from 'react-player';
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HomeProfile = () => {

    let userData = Storage.get("userData")
    const history = useHistory()

    const paramsUserId = useParams()

    const { control, handleSubmit, setValue } = useForm();

    const [pageLoading, setPageLoading] = useState(false)

    const [displayProfileData, setDisplayProfileData] = useState([])
    const [profileData, setProfileData] = useState([])

    const [commentsData, setCommentsData] = useState([])

    const [commentModal, setCommentModal] = useState(false)
    const [reportModal, setReportModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)

    const commentToggle = () => setCommentModal(!commentModal)
    const reportToggle = () => setReportModal(!reportModal)
    const deleteToggle = () => setDeleteModal(!deleteModal)

    const [commentIndex, setCommentIndex] = useState()
    const [reportIndex, setReportIndex] = useState()
    const [deleteIndex, setDeleteIndex] = useState()

    //PostLike
    const [like, setLike] = useState([])
    const [dislikeButton, setDislikeButton] = useState([])
    const [likeCommentButton, setLikeCommentButton] = useState([])

    //Modal Popup
    const [popup, setPopup] = useState()

    //ToolTip
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const showToastMessageSuccess = (info) => {
        toast.success(info, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    useEffect(() => {
        getUserProfileData(true);
        AnchorScroll();
    }, [paramsUserId])

    const getUserProfileData = (item) => {
        const params = {
            limit: 50,
            page: 1,
            order: "desc",
            userId: paramsUserId.id
        }
        setPageLoading(item)
        Service.post({ url: '/activity/posts/userProfileSearch', body: JSON.stringify(params) })
            .then((response) => {
                setProfileData(response.data.profile)
                setDisplayProfileData(response.data.posts.filter(item => item.groupId === 0))
                setPageLoading(false)
            }).catch((error) => {
                setPageLoading(false)
            })
    }

    const deletePost = (item) => {
        deleteToggle();
        setDeleteIndex(item)
    }

    const reportPost = (item) => {
        reportToggle();
        setReportIndex(item.id)
    }

    const editComment = (item) => {
        setPopup("Edit")
        setValue("Comment", item.message)
        setCommentIndex(item)
    }

    const deleteComment = (item) => {
        Service.delete({ url: `/activity/comments/${item.id}`, body: JSON.stringify() })
            .then((response) => {
                commentPost(item.post_id, false);
                getUserProfileData(null);
            }).catch((error) => { })
    }

    const likePost = (item) => {
        const postid = item.id;
        const userid = userData.id
        let index = like.findIndex((x) => x === postid);
        if (item.isLiked > 0) {
            like.splice(index, 1);
            Service.delete({ url: `/activity/postsLike/${postid}`, body: JSON.stringify() })
                .then((response) => {
                    getUserProfileData(null);
                }).catch((error) => { })
        }
        else {
            like.push(item.id);
            const params = {
                postId: postid,
            }
            Service.post({ url: '/activity/postsLike', body: JSON.stringify(params) })
                .then((response) => {
                    getUserProfileData(null);
                }).catch((error) => { })
        }
        setLike([...like]);
    }

    const dislikePost = (item) => {
        const postid = item.id;
        let index = dislikeButton.findIndex((x) => x === item.id);
        if (item.isDisliked > 0) {
            dislikeButton.splice(index, 1);
            Service.delete({ url: `/activity/postsDislike/${postid}`, body: JSON.stringify() })
                .then((response) => {
                    getUserProfileData(null);
                }).catch((error) => { })
        }
        else {
            dislikeButton.push(item.id);
            const params = {
                postId: postid
            }
            Service.post({ url: '/activity/postsDislike', body: JSON.stringify(params) })
                .then((response) => {
                    getUserProfileData(null);
                }).catch((error) => { })
        }
        setDislikeButton([...dislikeButton]);
    }

    const commentPost = (item, type = true) => {
        const params = {
            limit: 50,
            page: 1,
            order: "desc",
            postId: item
        }
        if (type === true) {
            setValue("Comment", "")
            commentToggle();
            setCommentIndex(item)
        }
        Service.post({ url: '/activity/comments/search', body: JSON.stringify(params) })
            .then((response) => {
                setCommentsData(response.data)
            }).catch((error) => { })
    }

    const likeComment = (item) => {
        const commentid = item.id;
        const userid = userData.id
        let index = likeCommentButton.findIndex((x) => x === item.id);
        if (item.isliked > 0) {
            likeCommentButton.splice(index, 1);
            Service.delete({ url: `/activity/commentsLike/${commentid}`, body: JSON.stringify() })
                .then((response) => {
                    commentPost(item.post_id, false);
                }).catch((error) => { })
        }
        else {
            likeCommentButton.push(item.id);
            const params = {
                commentId: commentid,
                userId: userid
            }
            Service.post({ url: '/activity/commentsLike', body: JSON.stringify(params) })
                .then((response) => {
                    commentPost(item.post_id, false);
                }).catch((error) => { })
        }
        setLikeCommentButton([...likeCommentButton]);
    }

    const onCommentSubmit = (data) => {
        const postid = commentIndex;
        const message = data.Comment;
        const params = {
            postId: postid,
            comment: message
        }
        const params1 = {
            postId: postid.post_id,
            comment: message
        }
        if (popup === "Edit") {
            Service.patch({ url: `/activity/comments/${postid.id}`, body: JSON.stringify(params1) })
                .then((response) => {
                    setValue("Comment", "")
                    commentPost(postid.post_id, false);
                    getUserProfileData(null);
                }).catch((error) => { })
        } else {
            Service.post({ url: '/activity/comments', body: JSON.stringify(params) })
                .then((response) => {
                    setValue("Comment", "")
                    commentPost(postid, false);
                    getUserProfileData(null);
                }).catch((error) => { })
        }
    }

    const onDeleteSubmit = () => {
        Service.delete({ url: `/activity/posts/${deleteIndex.id}`, body: JSON.stringify() })
            .then((response) => {
                deleteToggle();
                getUserProfileData(null);
            }).catch((error) => { })
    }

    const onReportSubmit = () => {
        const params = {
            postId: reportIndex
        }
        Service.post({ url: '/activity/posts/report', body: JSON.stringify(params) })
            .then((response) => {
                reportToggle();
                showToastMessageSuccess("Post reported successfully")
            }).catch((error) => { })
    }

    function navigate(event) {
        event.preventDefault()
        if (event.target.tagName === 'A') {
            history.push(event.target.getAttribute('href'))
        }
    }

    const userProfile = (item) => {
        if (item.user === userData.id) {
            history.push(`/home`)
        } else {
            history.push(`/home/${item.user}`)
        }
    }

    const userCommentProfile = (item) => {
        if (item.user_id === userData.id) {
            history.push(`/home`)
        } else {
            history.push(`/home/${item.user_id}`)
        }
    }

    return (
        <>
            <div className="px-4 bg-right">
                <SearchInput />
                <Row>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="mt-4">
                        <div className="mx-sm-5 px-sm-5 mx-lg-0 px-lg-0">
                            <Menu />
                        </div>
                        <div className="d-none d-lg-block mt-lg-4 stickey">
                            <MenuItems />
                        </div>
                    </Col>
                    <Col xl="6" lg="6" md="12" sm="12" xs="12" >
                        {
                            pageLoading ? <div className="bg-primary2 p-3 m-3 text-center"><Spinner color="light" /></div> : <div className="middle-info-bg h-lg-100 p-2 my-4">
                                <div className="bg-primary m-2 m-md-3 video-section">
                                    <div className="position-parent">
                                        <img src={require('../../assets/img/Landing_Page/Frame1.png')} className="image-width d-block" />
                                        <div className={profileData.UserProfileImage ? "avatar-img6 position-abs6" : "avatar-img-original position-abs6"}>
                                            <img src={profileData.UserProfileImage ? `${Config.MEDIA_URL}${profileData.UserProfileImage}` : require('../../assets/img/Landing_Page/logo.png')} alt="avatar"
                                                className="avatar-imginside1"
                                                width={"90%"} height="90%" />
                                        </div>
                                    </div>

                                    <div className="text-center pt-3">
                                        <h3 className="mb-0">{profileData.userName ? profileData.userName : `User ${profileData.id}`}</h3>
                                    </div>
                                    <div className="text-center py-2">
                                        <p>Total Rewards - <span>{profileData.totalRemarks}</span></p>
                                    </div>
                                </div>
                                {
                                    displayProfileData && displayProfileData.length > 0 ?
                                        displayProfileData && displayProfileData.length > 0 && displayProfileData.map((item, index) => {
                                            return <div className="bg-primary m-2 m-md-3 video-section" id={item.id}>
                                                <div className="d-flex align-items-center justify-content-between py-3 pl-3 pr-3">
                                                    <div className="d-flex align-items-center justify-content-start cursor-pointer" onClick={() => userProfile(item)}>
                                                        <div className={item.UserProfileImage ? "avatar-img1 ss" : "avatar-img1-original"}>
                                                            <img src={item.UserProfileImage ? `${Config.MEDIA_URL}${item.UserProfileImage}` : require('../../assets/img/Landing_Page/logo.png')} className="avatar-imginside" alt="" width={"90%"} height="90%" />
                                                        </div>
                                                        <div className="ml-2">
                                                            <h4 className="mb-0">{item.userName ? item.userName : `USER ${item.user}`}</h4>
                                                            <span>Posted at {moment(item.date).format('LLL')}</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        userData && userData.r === "n" ? <div className="d-flex flex-row align-items-center justify-content-start">
                                                            <div className="mb-3">
                                                                {item.isPin > 0 && <AiOutlinePushpin color="#FFF" size={30} />}
                                                            </div>
                                                            <UncontrolledDropdown className='dropdown-user nav-item'>
                                                                <DropdownToggle href='/' tag='a' className='align-items-center' onClick={e => e.preventDefault()} >
                                                                    <img src={require('../../assets/img/Landing_Page/menu1.png')} className="mr-3 mb-3" alt="" />
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-right optionpost">
                                                                    {/* <DropdownItem id={index} key={index} to='#' onClick={() => editPost(item)}>
                                                                        <span className='align-middle optionpostitem'>Edit Post</span>
                                                                    </DropdownItem> */}
                                                                    <DropdownItem to='#' onClick={() => deletePost(item)}>
                                                                        <span className='align-middle optionpostitem'>Delete Post</span>
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </div> : <div className="mr-3">
                                                            {item.isPin > 0 && <AiOutlinePushpin color="#FFF" size={30} />}
                                                        </div>
                                                    }
                                                </div>
                                                <p onClick={(e) => navigate(e)} dangerouslySetInnerHTML={{ __html: item.postMessage.split("[/1319/]")[0] }} className="mx-4 mb-4 text-capitalize text-justify"></p>
                                                {
                                                    item.postMessage.split("[/1319/]")[1] === "null" || item.postMessage.split("[/1319/]")[1] === undefined ? <></> : <img src={`${Config.MEDIA_URL}${item.postMessage.split("[/1319/]")[1]}`} className="px-3 pb-3 image-width" height={'fit-content'} alt="" />
                                                }
                                                {
                                                    item.postMessage.split("[/1319/]")[2] === "null" || item.postMessage.split("[/1319/]")[2] === undefined ? <></> : <ReactPlayer className="px-3 image-width rounded" url={item.postMessage.split("[/1319/]")[2]} controls={true} width={"100%"} />
                                                }
                                                <Row className="mx-3 mt-4 actiongrid py-2 px-2">
                                                    <Col className="d-flex flex-sm-row flex-column justify-content-sm-between justify-content-center align-items-center" lg={4} md={4} sm={4}
                                                        xs={12}>
                                                        <a href="/" onClick={(e) => e.preventDefault()} className="actiontypes">{item.likes} {item.likes > 1 ? "Likes" : "Like"}</a>
                                                        <a href="/" onClick={(e) => e.preventDefault()} className="actiontypes">{item.dislikes} {item.dislikes > 1 ? "Dislikes" : "Dislike"}</a>
                                                    </Col>
                                                    <Col className="d-flex flex-sm-row flex-column justify-content-center justify-content-sm-end align-items-center" lg={8} md={8} sm={8} xs={12}>  
                                                        <a href="/" onClick={(e) => { e.preventDefault(); commentPost(item.id) }} className="pr-md-5 actiontypes">{item.comments} {item.comments > 1 ? "Comments" : "Comment"}</a>
                                                    </Col>
                                                </Row>
                                                {
                                                    item.postCmt && <div className="mx-3 mt-4 actiongrid d-flex justify-content-start align-items-center py-2 px-0 row">
                                                        <Col lg={2} md={2} sm={2} xs={12} className="d-flex justify-content-start cursor-pointer" onClick={() => commentPost(item.id)}>
                                                            <div className={item.cmtUserProfile ? "avatar-img1" : "avatar-img1-original"}>
                                                                <img src={item.cmtUserProfile ? `${Config.MEDIA_URL}${item.cmtUserProfile}` : require('../../assets/img/Landing_Page/logo.png')} className="avatar-imginside" alt="" width={"90%"} height="90%" />
                                                            </div>
                                                        </Col>
                                                        <Col lg={10} md={10} sm={10} xs={12}>
                                                            <div className="comment-bg">
                                                                <h6>{item.cmtUserName ? item.cmtUserName : "USER"}</h6>
                                                                <span className="text-muted">{item.postCmt}</span>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                }
                                                <Row className="mt-3 pb-4 mx-1">
                                                    {
                                                        <><Col className="d-flex mb-2 justify-content-start justify-content-lg-center align-items-center cursor-pointer" onClick={() => likePost(item)}>
                                                            {
                                                                item.isLiked > 0 ? <AiFillLike color="#fea321" size={"2em"} /> : <AiOutlineLike size={"2em"} />
                                                            }
                                                            <h5 className={`ml-1 mb-0 ${item.isLiked > 0 ? "like-color" : "text-muted"}`}>Dope</h5>
                                                        </Col>
                                                            <Col className="d-flex mb-2 justify-content-start justify-content-lg-center align-items-center mr-sm-3 cursor-pointer" onClick={() => dislikePost(item)}>
                                                                {
                                                                    item.isDisliked > 0 ? <AiFillDislike color="white" size={"2em"} /> : <AiOutlineDislike size={"2em"} />
                                                                }
                                                                <h5 className={`ml-1 mb-0 ${item.isDisliked > 0 ? "dislike-color" : "text-muted"}`}>Wack</h5>
                                                            </Col>
                                                            <Col className="d-flex mb-2 justify-content-start justify-content-lg-center align-items-center mr-sm-3 cursor-pointer" onClick={() => commentPost(item.id)}>
                                                                <BiCommentMinus size={"2em"} />
                                                                <h5 className="ml-1 mb-0 text-muted">Comment</h5>
                                                            </Col>
                                                            <Col className="d-flex mb-2 justify-content-start justify-content-lg-center align-items-center cursor-pointer" onClick={() => reportPost(item)}>
                                                                <TbReportSearch size={"2em"} />
                                                                <h5 className="ml-1 mb-0 text-muted">Report</h5>
                                                            </Col></>
                                                    }
                                                </Row>
                                            </div>
                                        }) : <div className="bg-primary p-3 m-3 text-center">
                                            <h6 className="mb-0">No Posts yet</h6>
                                        </div>
                                }

                                {/* <------- Delete Modal ------------> */}

                                <Modal isOpen={deleteModal} toggle={deleteToggle}>
                                    <ModalHeader toggle={deleteToggle}>Delete Social Post</ModalHeader>
                                    <hr className="w-100 modal-line" />
                                    <ModalBody>
                                        <span>Do you really want to delete the post ?</span>
                                        <ModalFooter>
                                            <Button color="success" type="submit" onClick={() => onDeleteSubmit()}>
                                                Delete
                                            </Button>{' '}
                                            <Button color="secondary" onClick={deleteToggle}>
                                                Cancel
                                            </Button>
                                        </ModalFooter>
                                    </ModalBody>
                                </Modal>

                                {/* <------- Comment Modal ------------> */}

                                <Modal isOpen={commentModal} toggle={commentToggle}>
                                    <ModalHeader toggle={commentToggle}>Comments</ModalHeader>
                                    <hr className="w-100 modal-line" />
                                    <div className="scrolling-enable1">
                                        <ModalBody>
                                            {
                                                commentsData && commentsData.length > 0 ? commentsData.map((item) => {
                                                    return <FormGroup>
                                                        <Row className="d-flex align-items-center" id={item.id}>
                                                            <Col lg={2} md={2} sm={2} xs={2} className='mb-3 d-flex cursor-pointer justify-content-center' onClick={() => userCommentProfile(item)}>
                                                                <div className={item.UserProfileImage ? "avatar-img3" : "avatar-img3-original"}>
                                                                    <img src={item.UserProfileImage ? `${Config.MEDIA_URL}${item.UserProfileImage}` : require('../../assets/img/Landing_Page/logo.png')} width={"100%"} height="100%" className="avatar-imginside" alt="" />
                                                                </div>
                                                            </Col>
                                                            <Col lg={7} md={7} sm={7} xs={7} className="justify-content-start p-0">
                                                                <Col className="comment-bg">
                                                                    <h6>{item.userName ? item.userName : `USER ${item.user_id}`}</h6>
                                                                    <span className="text-muted">{item.message}</span>
                                                                </Col>
                                                                <Col className="d-flex justify-content-start" lg={12}>
                                                                    <a href="/" onClick={(e) => e.preventDefault()} className="actiontypes">{item.likes} {item.likes > 1 ? "Likes" : "Like"}</a>
                                                                </Col>
                                                            </Col>
                                                            {
                                                                <Col lg={2} md={2} sm={2} xs={2} id="TooltipExample" className='cursor-pointer mb-3 text-center' onClick={() => likeComment(item)}>
                                                                    <Tooltip
                                                                        isOpen={tooltipOpen}
                                                                        target="TooltipExample"
                                                                        toggle={toggle}
                                                                    >
                                                                        Like Comment
                                                                    </Tooltip>
                                                                    {
                                                                        item.isliked > 0 ? <AiFillLike className="com-like-button" size={"35px"} /> : <AiOutlineLike className="com-like-button1" size={"35px"} />
                                                                    }
                                                                </Col>
                                                            }

                                                            {
                                                                item.user_id === userData.id ? <Col lg={1} md={1} sm={1} xs={1} className="p-0">
                                                                    <UncontrolledDropdown className='dropdown-user nav-item' direction="down">
                                                                        <DropdownToggle href='/' tag='a' className='align-items-center' onClick={e => e.preventDefault()} >
                                                                            <img src={require('.././../assets/img/Landing_Page/menu1.png')} className="mb-3" alt="" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu container="body" className="dropdown-menu-right optionpost">
                                                                            <DropdownItem to='#' onClick={() => editComment(item)}>
                                                                                <span className='align-middle optionpostitem'>Edit Comment</span>
                                                                            </DropdownItem>
                                                                            <DropdownItem to='#' onClick={() => deleteComment(item)}>
                                                                                <span className='align-middle optionpostitem'>Delete Comment</span>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </Col> : <span></span>
                                                            }
                                                        </Row>
                                                    </FormGroup>
                                                }) : <div className="text-center"><span>No Comments yet</span></div>
                                            }
                                        </ModalBody>
                                    </div>
                                    <hr className="w-100 modal-line" />
                                    <ModalFooter className="py-0 main-modal-comment d-flex align-items-center justify-content-center">
                                        <Form onSubmit={handleSubmit(onCommentSubmit)}>
                                            {
                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} md={3} sm={3} xs={12} className="pl-sm-3"><div className={userData.avatar ? "avatar-img3" : "avatar-img3-original"}><img src={userData.avatar ? `${Config.MEDIA_URL}${userData.avatar}` : require('../../assets/img/Landing_Page/logo.png')} width={"100%"} height="100%" className="avatar-imginside" alt="" /></div></Col>
                                                    <Col lg={7} md={6} sm={6} xs={9} className='p-0 mt-2'>
                                                        <FormGroup>
                                                            <Controller
                                                                name="Comment"
                                                                control={control}
                                                                id="comment"
                                                                render={({ field }) => <Input placeholder="Write a Comment" type="text" {...field} />}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={3} md={3} sm={3} xs={3} className="text-right">
                                                        <Button color="success" type="submit">
                                                            Post
                                                        </Button>{' '}
                                                    </Col>
                                                </Row>
                                            }
                                        </Form>
                                    </ModalFooter>
                                </Modal>

                                {/* <------- Report Modal ------------> */}

                                <Modal isOpen={reportModal} toggle={reportToggle}>
                                    <ModalHeader toggle={reportToggle}>Report</ModalHeader>
                                    <hr className="w-100 modal-line" />
                                    <ModalBody>
                                        <Form onSubmit={handleSubmit(onReportSubmit)}>
                                            <FormGroup>
                                                <Label for="report">
                                                    Do you want to report this post ?
                                                </Label>
                                            </FormGroup>
                                            <hr className="w-100 modal-line" />
                                            <ModalFooter>
                                                <Button color="success" type="submit">
                                                    Yes
                                                </Button>{' '}
                                                <Button color="secondary" onClick={reportToggle}>
                                                    No
                                                </Button>
                                            </ModalFooter>
                                        </Form>
                                    </ModalBody>
                                </Modal>
                            </div>
                        }
                    </Col>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="p-0 my-5 mt-sm-0">
                        <Likes />
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default HomeProfile