import braveImg from "../assets/img/Landing_Page/brave-icon.png"
import coinbaseImg from "../assets/img/Landing_Page/coinbase-icon.png"
import metamaskImg from "../assets/img/Landing_Page/metamask-icon.png"
import walletImg from "../assets/img/Landing_Page/walletconnect-icon.png"
export const AnchorScroll = () => {
    setTimeout(() => {
        let currentLocation = window.location.href;
        const hasAnchor = currentLocation.includes("/#");
        if (hasAnchor) {
            const anchorId = `${currentLocation.substring(currentLocation.indexOf("#") + 1)}`;
            const anchorElement = document.getElementById(anchorId);
            if (anchorElement) {
                setTimeout(() => {
                    anchorElement.scrollIntoView({ behavior: "smooth" });
                }, 200);
            }
        } else {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            })
        }
    }, 200);
}

export const truncateAddress = (address) => {
    const a = String(address)
    if (a && a.length > 5) {
        return `${a.substring(0, 5)}...${a.slice(a.length - 5)}`
    }
    return a
}

export const getWalletLogo = (walletName) => {
    let wallet = walletName
    if (wallet === null) wallet = localStorage.getItem('provider')
    if (wallet === 'coinbase' || wallet === 'coinbaseWallet') return coinbaseImg
    if (wallet === 'walletconnect' || wallet === 'walletConnect') return walletImg
    if (wallet === 'brave') return braveImg
    return metamaskImg
}