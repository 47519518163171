import React, { useEffect, useState } from "react";
import { Row, Col, Button, Spinner } from "reactstrap"
import { Menu } from "components/menu";
import { Likes } from "components/Likes";
import { SearchInput } from "components/search";
import { MenuItems } from "components/MenuItems";
import { AnchorScroll } from "helper";
import { Service, Storage } from "services/service";
import { Link, useHistory } from "react-router-dom";
import { AiOutlineClockCircle } from "react-icons/ai";
import Avatar from 'react-avatar';

const Admin = () => {

    const [data, setData] = useState([])
    const [pageLoading, setPageLoading] = useState(false)

    useEffect(() => {
        postReports();
        AnchorScroll();
    }, [])

    const viewReport = (itemId) => {
        Service.get({ url: `/activity/posts/report/${itemId}`, body: JSON.stringify() })
        .then((response) => {}).catch((error) => { })
    }

    const postReports = () => {
        const params = {
            limit: 1000,
            page: 1,
            order: "desc",
        }
        setPageLoading(true)
        Service.post({ url: '/activity/posts/report/search', body: JSON.stringify(params) })
            .then((response) => {
                setData(response.data)
                setPageLoading(false)
            }).catch((error) => {
                setPageLoading(false)
            })
    }


    return (
        <>
            <div className="px-4 bg-right">
                <SearchInput />
                <Row>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="mt-4">
                        <div className="mx-sm-5 px-sm-5 mx-lg-0 px-lg-0">
                            <Menu />
                        </div>
                        <div className="d-none d-lg-block mt-lg-4 stickey">
                            <MenuItems />
                        </div>
                    </Col>
                    <Col xl="6" lg="6" md="12" sm="12" xs="12" >
                        <div className="middle-info-bg h-lg-100 p-3 p-sm-4 mt-4">
                            <h3 className="mb-1">Reports</h3>
                            {
                                pageLoading ? <div className="bg-primary p-3 m-3 text-center"><Spinner color="light" /></div> :
                                    data && data.length > 0 && data.map((item, index) => {
                                        const status = item.status == 1 ? "" : " (unseen) " 
                                        return <div key={index} className={`${status}  bg-primary px-1 mx-1 my-3 m-sm-3`}>
                                            <Row className="d-flex align-items-center justify-content-center p-2">
                                                <Col lg="4" md="4" sm="4" className="text-center">
                                                    <Avatar key={item.id} size="45" colors={['#00f2c3', "#5e72e4"]} />
                                                </Col>
                                                <Col lg="8" md="8" sm="8" className="pr-0">
                                                    <Link key={index.id} onClick={() => viewReport(item.reportID) } to={`/community/${item.GroupId}/#${item.PostID}`}>
                                                        <h6 className="mb-1 text-center text-sm-left">Reported by - {item.userName ? item.userName : `User ${item.user} ${status}  `}</h6>
                                                        <div className="d-flex flex-column flex-sm-row flex-lg-column flex-xl-row text-center text-sm-left mt-3">
                                                            <span className="text-secondary mr-2">Group Name - {item.GroupName ? item.GroupName : "Group not exist"}</span>
                                                        </div>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </div>
                                    })
                            }
                        </div>
                    </Col>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="my-5 mt-sm-0">
                        <Likes />
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default Admin