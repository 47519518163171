import React, { useState, useEffect } from "react"
import {
  Row, Col, Button, Label, Modal
} from "reactstrap"
import { useHistory } from 'react-router-dom'
import Flatpickr from "react-flatpickr"
import Carousel from "react-elastic-carousel"
/*wallet imports*/
import { useWeb3React } from '@web3-react/core'
import connectors from "helper/WalletConnector"
import activateInjectedProvider from "helper/WalletHelp"
import { Service, Storage } from "services/service"
import Image1 from "../../assets/img/Landing_Page/Vector1.png"
import Image2 from "../../assets/img/Landing_Page/Vector2.png"
import Image3 from "../../assets/img/Landing_Page/Vector3.png"
import moment from "moment"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const nonceMsg = `Hi there from Outrider! Sign this message to prove you have access to this wallet and we'll log you in. This won't cost you any Ether. To stop hackers using your wallet, here's a unique message ID they can't guess: `


export const PrivacyPolicy = () => {
  return (
    <div className="model-border p-4">
      <h1>Outrider Privacy Policy</h1>

      <p>Effective Date: June 18, 2024</p>
      <p>
      Welcome to Outrider! We are committed to protecting the privacy and security of our users' information. This Privacy Policy outlines how we collect, use, store, and protect the personal information of our users. By using our platform, you agree to the collection and use of information in accordance with this policy.
      </p>
      <h2>Information We Collect</h2>
      <h3>Information You Provide</h3>

      <p>
      <ul>
      <li>Account Information: When you create an account, we collect your wallet address and any other information you provide. </li>
      <li>Community Interactions: When you join communities, post, edit, update, follow posts, or communicate with others, we collect the content of your communications and any other information you provide.</li>
      </ul>
      </p>

      <h3>Automated Information Collection</h3>

      <p>
        Usage Data: We collect information on how the platform is accessed and used. This may include details such as your computer's IP address, browser type, browser version, our platform's pages that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data
      </p>

      <h2>How We Use Your Information</h2>

      <p>
        We use the information we collect to: 
        <ul>
        <li>Provide, maintain, and improve our platform. </li>
        <li>Allow you to participate in interactive features of our service when you choose to do so. </li>
        <li>Provide customer support. </li>
        <li>Gather analysis or valuable information so that we can improve our platform. </li>
        <li>Monitor the usage of our platform. </li>
        <li>Detect, prevent, and address technical issues. </li>
        <li>Communicate with you about updates, features, and other information related to the platform or your communities.</li>
        </ul>
      </p>

      <h2>Sharing Your Information</h2>

      <p>
      We may share your personal information with third parties in the following situations: 
      <ul>
      <li>Service Providers: To facilitate our platform, to provide the platform on our behalf, to perform platform-related services, or to assist us in analysing how our platform is used. </li>
      <li>Compliance with Laws: We will disclose your personal information where required to do so by law or subpoena.</li>
      </ul>
      </p>

      <h2>Data Security</h2>

      <p>
      We value your trust in providing us your personal information, thus we strive to use commercially acceptable means of protecting it. However, no method of transmission over the internet or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
      </p>

      <h2>Children's Privacy</h2>

      <p>
      Our platform does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers.
        </p>

      <h2>Changes to This Privacy Policy</h2>

      <p>
      We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
      </p>

      <h2>International Data Transfers</h2>

      <p>Outrider LLC operates globally, which means your information may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. If you are located outside the United States and choose to provide information to us, please note that we transfer the data, including personal information, to the United States and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer. Outrider LLC will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your personal data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

      <h2>Your Data Protection Rights</h2>

      <p>Outrider LLC aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your personal information. In accordance with applicable laws, you have the right to:
      <ul>
      <li>Access: You have the right to request copies of your personal data. </li>
      <li>Rectification: You have the right to request that we correct any information you believe is inaccurate or incomplete. </li>
      <li>Erasure: You have the right to request that we erase your personal data, under certain conditions.</li>
      <li>Restrict processing: You have the right to request that we restrict the processing of your personal data, under certain conditions. </li>
      <li>Object to processing: You have the right to object to our processing of your personal data, under certain conditions. </li>
      <li>Data portability: You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions. </li>
      </ul>
      If you wish to exercise any of these rights, please contact us. We will respond to your request within one month.
      </p>

      <h2>Data Retention</h2>

      <p>
      Outrider LLC will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. Outrider LLC will also retain usage data for internal analysis purposes. Usage data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our platform, or we are legally obligated to retain this data for longer periods.
      </p>
    </div>
  );
};

export const TermsOfService = () => {
  return (
    <div className="model-border p-4">
      <h1>Outrider Terms and Conditions</h1>
      
      <p>Effective Date: June 18, 2024</p>
      <p>Welcome to Outrider! These terms and conditions outline the rules and regulations for the use of the Outrider platform, located at <a href="https://outrider.net/">https://outrider.net/</a>. By accessing this platform, you agree to accept these terms and conditions in full. Do not continue to use Outrider if you do not agree to all of the terms and conditions stated on this page.</p>

      <h2>Definitions</h2>

      <p>The following terminology applies to these Terms and Conditions, the Privacy Policy, any Disclaimer Notice, and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing this platform and accepting the terms and conditions of Outrider. "The Company", "Ourselves", "We", "Our" and "Us", refers to Outrider LLC. "Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client or ourselves.</p>

      <h2>Use of the Platform</h2>
      <h3>License to Use Platform</h3>
      <p>Subject to your compliance with these Terms, Outrider LLC grants you a non-exclusive, non-transferable, revocable license to use the platform solely for your personal, non-commercial use.</p>
      <h3>Prohibited Uses</h3>
      <p>You are expressly prohibited from:
      <ul>
      <li>Using the platform in any way that is, or may be, damaging to this platform. </li>
      <li>Using the platform in any way that impacts user access to this platform. </li>
      <li>Using the platform contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the platform, or to any person or business entity.</li>
      </ul>
      </p>


      <h2>Content</h2>
      <h3>Intellectual Property Rights</h3>
      <p>Other than the content you own, under these Terms, Outrider LLC and/or its licensors own all the intellectual property rights and materials contained in this platform.</p>
      <h3>User-Generated Content</h3>
      <p>You grant to Outrider LLC a non-exclusive, worldwide, irrevocable, royalty-free license to use, reproduce, adapt, publish, translate and distribute your user-generated content in any existing or future media. You also grant to Outrider LLC the right to sublicense these rights and the right to bring an action for infringement of these rights.</p>

      <h2>Privacy</h2>

      <p>Your privacy is critically important to us. Our Privacy Policy (linked separately) governs the handling, storage, and use of personal information and is incorporated by reference into these Terms and Conditions.</p>

      <h2>Limitation of Liability</h2>

      <p>In no event shall Outrider LLC, nor any of its officers, directors, or employees, be liable for anything arising out of or in any way connected with your use of this platform whether such liability is under contract, tort or otherwise.</p>

      <h2>Indemnification</h2>

      <p>You hereby indemnify to the fullest extent Outrider LLC from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>

      <h2>Severability</h2>

      <p>If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.</p>

      <h2>Variation of Terms</h2>

      <p>Outrider LLC is permitted to revise these Terms at any time as it sees fit, and by using this platform you are expected to review these Terms on a regular basis.</p>

      <h2>Assignment</h2>

      <p>Outrider LLC is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>

      <h2>Entire Agreement</h2>

      <p>These Terms, including any legal notices and disclaimers contained on this platform, constitute the entire agreement between Outrider LLC and you in relation to your use of this platform, and supersede all prior agreements and understandings.</p>

      <h2>Governing Law & Jurisdiction</h2>

      <p>These Terms will be governed by and construed in accordance with the laws of Jefferson County in the State of Colorado and you submit to the non-exclusive jurisdiction of the state and federal courts located in Jefferson County, Colorado for the resolution of any disputes.</p>
    </div>
  );
};


export default function Banner() {

  const history = useHistory()

  let userData = Storage.get("userData")

  const [primaryData, setPrimaryData] = useState()
  const [open, setIsOpen] = useState(false)
  const [signature, setSignature] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const [infoMsg, setInfoMsg] = useState(null)
  const [triggerLogin, setTriggerLogin] = useState(false)
  const [nonce, setNonce] = useState(null)
  const [answerZodiac, setAnswerZodiac] = useState("")
  const [answerWombat, setAnswerWombat] = useState("")
  const [answerFaction, setAnswerFaction] = useState("")
  const [walletType, setWalletType] = useState("")
  const [checkRead, setCheckRead] = useState(true)
  const [validFlag, setValidFlag] = useState("")
  const [birthdayDate, setBirthdayDate] = useState()
  const [termOfServiceOpen, setTermOfServiceOpen] = useState(false);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const { account, active, activate, chainId, library } = useWeb3React();

  let provider = localStorage.getItem('provider')

  /**web3 connect wallet onclick functionality */
  const connectWallet = (walletName) => {
    if (walletName) {
      localStorage.setItem('provider', walletName)
      if (walletName === 'injected') {
        activateInjectedProvider('injected')
        activate(connectors.injected)
      } else if (walletName === 'walletConnect') {
        activate(connectors.walletConnect);
      } else if (walletName === 'coinbaseWallet') {
        activate(connectors.coinbaseWallet)
      }
    } else {
      showToastMessageError("Select Wallet First and Get Started")
    }
  }

  const showToastMessageSuccess = (info) => {
    toast.success(info, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  const showToastMessageError = (info) => {
    toast.error(info, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  // const checkValid = (account) => {
  //   if (account) {
  //     Service.post({
  //       url: '/user/verifyWalletAddress',
  //       body: JSON.stringify({
  //         address: account
  //       })
  //     }).then((res) => {
  //       if (res.status === 'error' && res.data) {
  //         setValidInfo(true)
  //       } else {
  //         setValidInfo(false)
  //         localStorage.removeItem('provider')
  //         setErrorMsg('Select Birthdate First')
  //         setInfoMsg(null)
  //       }
  //     }).catch((err) => {
  //       localStorage.removeItem('provider')
  //       setErrorMsg('Select Birthdate First')
  //       setInfoMsg(null)
  //     })
  //   }
  // }

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (account) {
  //       checkValid(account)
  //     }
  //   }, 2000);
  // }, [account])

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 }
  ]

  const changeTermOfServiceVisibility = (flag) => {
    setTermOfServiceOpen(flag)
  }

  const changePrivacyPolicyVisibility = (flag) => {
    setPrivacyPolicyOpen(flag)
  }

  const toggleOpen = () => {
    document.getElementById("root").style.opacity = "0.2";
    setIsOpen(!open)
  }

  const toggleClose = () => {
    document.getElementById("root").style.opacity = "1";
    setIsOpen(!open)
  }

  const slides = [
    {
      id: 1,
      image: Image1,
      text1: "Zodiac",
      text2: userData && userData.zodiac
    },
    {
      id: 2,
      image: Image2,
      text1: "Wombat66",
      text2: userData && userData.wombat
    },
    {
      id: 3,
      image: Image3,
      text1: "Faction",
      text2: userData && userData.faction
    }
  ]

  const zodiacData = [
    {
      id: 1,
      zodiacName: "Aries",
      startDate: "21 March 2022",
      endDate: "19 April 2022",
      zodiacData: ["Center of Attention", "Bold and ambitious", "Passionate", "Motivated", "Confident", "leader"],
    },
    {
      id: 2,
      zodiacName: "Taurus",
      startDate: "20 April 2022",
      endDate: "20 May 2022",
      zodiacData: ["Enjoy Relaxing", "Surrounded by soft sounds", "Love, Beauty and money", "Comfort and Luxury", "Content when pampered", "Intense neediness", "Prone to anger and can be scary when enraged"],
    },
    {
      id: 3,
      zodiacName: "Gemini",
      startDate: "21 May 2022",
      endDate: "21 June 2022",
      zodiacData: ["Many Interests", "Two-faced", "Playful and Curious", "Social Butterflies", "Quick witted", "Very indecisive and unreliable"],
    },
    {
      id: 4,
      zodiacName: "Cancer",
      startDate: "21 June 2022",
      endDate: "22 July 2022",
      zodiacData: ["Highly Intuitive Psychic abilities", "Self protective", "Hard shell come off as cold", "Gentle nature", "Genuine Compassion", "nurturing", "sensitive", "compassionate", "self-protective", "security-seeking", "loving", "goofy", "ingratiating sense of humor"],
    },
    {
      id: 5,
      zodiacName: "Leo",
      startDate: "23 July 2022",
      endDate: "22 August 2022",
      zodiacData: ["Bask in the spotlight", "Celebrate themselves", "Drama filled romance", "Playful", "Artistically inspired", "drama-adoring", "ambitious", "loyal", "fiercely protective", "generous", "luxury-loving", "sunny", "big-hearted"],
    },
    {
      id: 6,
      zodiacName: "Virgo",
      startDate: "23 August 2022",
      endDate: "22 September 2022",
      zodiacData: ["Presence in the material world", "Logical and practical", "Systematic", "Perfectionist and wanting to learn", "Hyper aware of detail", "methodical", "quick thinkers", "mental energy", "stressed", "tense"],
    },
    {
      id: 7,
      zodiacName: "Libra",
      startDate: "23 September 2022",
      endDate: "22 October 2022",
      zodiacData: ["Balance and Harmony", "Wants equilibrium in all parts of life", "Love art and intellectualism", "Need love companionship", "Always surrounded with stunning objects", "extroverted", "cosy", "friendly", "balance", "harmony", "peace", "justice", "charm", "intelligence", "frankness", "persuasion", "seamless connectivity", "well-equipped"],
    },
    {
      id: 8,
      zodiacName: "Scorpio",
      startDate: "23 October 2022",
      endDate: "21 November 2022",
      zodiacData: ["Passionate and powerful", "Psychic and emotional realm", "Very intuitive", "Wait to strike when its least expected", "sexy", "fearlessness", "boldness", "passion", "creativity", "fierce loyalty"],
    },
    {
      id: 9,
      zodiacName: "Sagittarius",
      startDate: "22 November 2022",
      endDate: "21 December 2022",
      zodiacData: ["Passionate", "Curious", "Intense", "Adaptive", "Very adventures", "spontaneous", "fun", "conversationalists"],
    },
    {
      id: 10,
      zodiacName: "Capricorn",
      startDate: "22 December 2022",
      endDate: "19 January 2022",
      zodiacData: ["Fearless ambition", "Limitless resilience", "Secret wild side", "Rebellious and untamed", "Troublemaker", "Ruler"],
    },
    {
      id: 11,
      zodiacName: "Aquarius",
      startDate: "20 January 2022",
      endDate: "18 February 2022",
      zodiacData: ["Most innovative", "Progressive", "Rebellious", "Humanitarian", "love", "support", "appreciation", "air", "lack a distinct form", "resist", "classification", "enthusiastic", "active", "calm", "sensitive"],
    },
    {
      id: 12,
      zodiacName: "Pisces",
      startDate: "19 February 2022",
      endDate: "20 March 2022",
      zodiacData: ["Always think Fantasy and Reality at once", "Psychic", "Empathetic", "Compassionate", "Become swallowed by emotions", "sympathetic", "happiness", "creative", "imaginative"],
    },

  ]

  const wombatData = ["Third Nipple", "Loss of Hair", "Arrow- no side effects barley a cold", "Turns Purple", "Inverts your Feet", "Rex -made him tall", "Teeth Fall out", "Brittle Bones", "Limb loss", "Pregnant", "Vanishing Fingerprints", "Blue Urine", "Munchies", "Hallucinations", "Farts", "Weight Gain", "No sex drive", "Blind", "Muscle Spasms", "Vampire", "Frankie the lion was human. This virus makes animal traits", "Eye Color", "Depression", "Memory Loss", "Arrow- Laser focus and eyesight diminished", "Killing Skin tissue", "Zombie", "Grow a tail", "Hair Gain", "Finger nail growth", "Turn to Frog", "Turn to a Fish", "Small Weiner", "Cancer", "western accent", "Scales", "Magnetic", "Gambling addiction", "Sleep walking", "mirror of themselves in the Metaverse", "Erections", "Bad Breath", "Tiredness", "Chapped Lips", "Bipolar", "Thirst", "Lactation", "Salivation", "Heart Rate", "Sweating", "Salt Dipostits", "Pale skin", "becoming a pirate", "thinking you are a cat", "feeling high all the timne", "thinking you are drunk all the time", "feel like you are living the same day over agin", "age 2x as fast", "age backwards", "Extreme Nose Hair", "Ear Growth", "Talk to animals", "Fly", "invisible", "Super strong", "Death murder kill attack is 2025 five years into virus drop"];


  const factionData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

  const gettingRandomTraits = (result) => {
    if (result) {
      const keyZodiac = Math.floor(Math.random() * result.zodiacData.length)
      const keyWombat = Math.floor(Math.random() * wombatData.length)
      const keyFaction = Math.floor(Math.random() * factionData.length)
      setAnswerZodiac(result.zodiacData[keyZodiac])
      setAnswerWombat(wombatData[keyWombat])
      setAnswerFaction(factionData[keyFaction])
    }
  }

  const settingZodiac = (e) => {
    const birthday = moment(e).format('Do MMMM YYYY').replace(/(\d+)(st|nd|rd|th)/, "$1").slice(0, -4) + 2022
    const result = zodiacData.filter((item) => {
      return Date.parse(birthday) >= Date.parse(item.startDate) && Date.parse(birthday) <= Date.parse(item.endDate)
    })
    gettingRandomTraits(result[0])
  }

  useEffect(() => {
    setTimeout(() => {
      provider = localStorage.getItem('provider')
      if (active && account && provider !== null) {
        setInfoMsg('Verifying with server...')
        setErrorMsg(null)
        Service.post({
          url: '/user/auth',
          body: JSON.stringify({
            address: account,
            network: chainId
          })
        }).then((res) => {
          if (res.status === 'success' && res.data) {
            const data = res.data
            setNonce(data.nonce)
            setValidFlag(data.flag === 0 ? "add" : "delete")
          }
        }).catch((err) => {
          localStorage.removeItem('provider')
          setErrorMsg('We were unable to connect. Please try again!')
          setInfoMsg(null)
        })
      } else if (chainId && chainId !== 1) {  //checking network  
        localStorage.removeItem('provider')
        setErrorMsg('Change your wallet network to Ethereum Mainnet!!')
        setInfoMsg(null)
      }
    }, 2000);
  }, [active, account, chainId, triggerLogin])

  const signMessage = async () => {
    if (!library) return;
    try {
      if (validFlag === "add" && answerZodiac === "" && answerWombat === "" && answerFaction === "") {
        setInfoMsg('Select Birthdate and Continue')
        showToastMessageError("Select Birthdate and Get Started")
      } else if (validFlag === "delete") {
        setInfoMsg('Please sign the login message to fully authenticate...')
        setErrorMsg(null)
        const signature = await library.provider.request({
          method: "personal_sign",
          params: [nonceMsg + nonce, account]
        });
        setSignature(signature)
      }
    } catch (err) {
      localStorage.removeItem('provider')
      setErrorMsg('We were unable to connect. Please try again!')
      setInfoMsg(null)
      window.location.reload(true)
    }
  };
  const login = async () => {
    let params;
    if (validFlag === "add") {
      params = JSON.stringify({
        address: account,
        network: chainId,
        zodiac: answerZodiac,
        wombat: answerWombat,
        faction: answerFaction,
        dob: birthdayDate,
        signature
      })
    } else {
      params = JSON.stringify({
        address: account,
        network: chainId,
        signature
      })
    }
    Service.post({
      url: '/user/signin',
      body: params
    }).then((res) => {
      if (res.status === 'success' && res.data) {
        const dataRes = res.data
        const data = {
          id: dataRes.user.id,
          name: dataRes.user.name ? dataRes.user.name : `${dataRes.user.role}${dataRes.user.id}`,
          walletAddress: dataRes.user.address,
          email: dataRes.user.email ? dataRes.user.email : `${dataRes.user.role}${dataRes.user.id}`,
          color: dataRes.user.color,
          role: dataRes.user.role,
          r: dataRes.user.role.substr(dataRes.user.role.length - 1),
          avatar: dataRes.user.avatar,
          zodiac: dataRes.user.zodiac,
          wombat: dataRes.user.wombat,
          faction: dataRes.user.faction,
          facebook: dataRes.user.facebook,
          twitter: dataRes.user.twitter,
          linkedin: dataRes.user.linkedin,
          ability: [
            {
              action: 'manage',
              subject: 'all'
            }
          ]
        }
        localStorage.setItem('userData', JSON.stringify(data))
        localStorage.setItem('token', dataRes.token)
        setPrimaryData(userData)
        if (validFlag === "add") {
          setIsOpen(true)
        } else {
          console.log(Storage.get("userData"))
          setTimeout(() => {
            console.log("inside now")
            if (Storage.get("userData")?.r === "r")  {
              history.push(`/babble`) 
            } else {
              history.push(`/cybot`)
            }
          }, 2000);
        }
      }
    }).catch((err) => {
      localStorage.removeItem('provider')
      setErrorMsg('We were unable to connect. Please try again!')
      setInfoMsg(null)
    })
  }

  useEffect(async () => {
    if (birthdayDate && answerZodiac && answerWombat && answerFaction && nonce && nonce !== null) {
      setInfoMsg('Please sign the login message to fully authenticate...')
      setErrorMsg(null)
      const signature = await library.provider.request({
        method: "personal_sign",
        params: [nonceMsg + nonce, account]
      });
      setSignature(signature)
    }
  }, [birthdayDate, answerZodiac, answerWombat, answerFaction, nonce])


  useEffect(() => {
    if (nonce && nonce !== null && validFlag) signMessage()
  }, [nonce, validFlag])

  useEffect(() => {
    if (signature && signature !== null && validFlag) {
      login()
    }
  }, [signature])

  useEffect(() => {
    if (userData && userData.r === "r" && (localStorage.getItem('token'))) {
      history.push(`/babble`)
    } else if (userData && userData.r === "n" && (localStorage.getItem('token'))) {
      history.push(`/cybot`)
    } else {
    history.push('/')
    }
  }, [])

  return (
    <>
      <div className="main-login-screen">

      <Row className="d-flex">
        <Col lg="5" className="d-none d-lg-block">
          <section className="banner-section">
            <h2 className="pb-3">Enter the Exclusive Outrider Platform To Engage With Groups In The Metaverse</h2>
            <img src={require('../../assets/img/Landing_Page/Line.png')} alt="" />
          </section>
        </Col>
        <Col lg="7" xs="12" sm="12" md="12">
          <section className="bg-right">
            <div className="text-center">
              <img src={require('../../assets/img/Landing_Page/logo.png')} alt="" />
            </div>
            <h1 className="login-text text-center text-lg-left">Login with your wallet</h1>
            <p className="text-secondary text-center text-lg-left">Cyberdope NFT Holders Will Get Full Access To Outrider</p>
            <h3 className="mt-5 bd-text text-center text-lg-left">Enter your birthday</h3>
            <Flatpickr
              id='departDate'
              className='form-control birthday-input'
              name='departDate'
              placeholder="DD-MM-YYYY"
              dateformat='d-m-Y'
              options={{ maxDate: new Date() }}
              onChange={(e) => { settingZodiac(e[0]); setBirthdayDate(moment(e[0]).format('ll')) }}
            />
            <h3 className="mt-5 mt-lg-3 bd-text text-center text-lg-left">Choose wallet</h3>
            <Row className="d-flex justify-content-between">
              <Col lg="4" xs="12" sm="4" md="4">
                <div className={walletType === "injected" ? "wallet-bg-first px-2 py-5 mr-sm-3 d-flex align-items-center flex-column justify-content-center cursor-pointer" : " wallet-bg px-2 py-5 mr-sm-3 d-flex align-items-center flex-column justify-content-center cursor-pointer"} onClick={() => setWalletType('injected')} >
                  <img height='40' width='40' src={require('../../assets/img/Landing_Page/metamask-icon.svg').default} alt="" />
                  <h4 className="mb-0">Metamask</h4>
                </div>
              </Col>
              <Col lg="4" xs="12" sm="4" md="4" className="mt-4 mt-sm-0">
                <div className={walletType === "walletConnect" ? "wallet-bg-first px-2 py-5 mr-sm-3 d-flex align-items-center flex-column justify-content-center cursor-pointer" : "wallet-bg px-2 py-5 mr-sm-3 d-flex align-items-center flex-column justify-content-center cursor-pointer"} onClick={() => setWalletType("walletConnect")}>
                  <img height='40' width='40' src={require('../../assets/img/Landing_Page/wallet-connect.svg').default} alt="" />
                  <h4 className="mb-0 text-center">Wallet Connect</h4>
                </div>
              </Col>
              <Col lg="4" xs="12" sm="4" md="4" className="mt-4 mt-sm-0">
                <div className={walletType === "coinbaseWallet" ? "wallet-bg-first px-2 py-5 d-flex align-items-center flex-column justify-content-center cursor-pointer" : "wallet-bg px-2 py-5 d-flex align-items-center flex-column justify-content-center cursor-pointer"} onClick={() => setWalletType("coinbaseWallet")}>
                  <img height='40' width='40' src={require('../../assets/img/Landing_Page/coinbase-icon.svg').default} alt="" />
              <h4 className="mb-0 text-center">Coinbase</h4>
                </div>
              </Col>
              <Col className="mt-3">
                {infoMsg && infoMsg !== null && <p className='text-success h5 my-1' >{infoMsg}</p>}
                {errorMsg && errorMsg !== null && <p className='text-danger h5 my-1' >{errorMsg}</p>}
              </Col>
            </Row>

            <div className="mt-5 d-flex align-items-center checkbox-main">
              <input type="checkbox" id="check" checked={checkRead} onClick={(e) => { setCheckRead(!checkRead)}} className="mb-2" />
              <Label for="check" name="check" className="text-capitalize check-text"><span>I have read and agree with</span> <span onClick={(e) => { e.preventDefault(); changeTermOfServiceVisibility(true) }} className="text-white">terms of service</span> <span>& our</span> <span className="text-white" onClick={ (e) => { e.preventDefault();  changePrivacyPolicyVisibility(true) }} >privacy policy</span></Label>
            </div>
            <div className="bg-banner-btn">
              <button className="form-control btn-bg mt-4 cursor-pointer" onClick={() => { connectWallet(walletType) }} ><span>Get Started</span></button>
            </div>
          </section>
        </Col>
      </Row>
      </div>

      <Modal className="bg-shade2 mt-0" isOpen={open} size="sm" >
        <Carousel breakPoints={breakPoints} className="mb-3" >
          {slides.map((slide, index) => {
            return (
              <div className="modal-slide-bg my-5 p-5 mr-3 text-center">
                <img src={slide.image} alt="" />
                <h4>{slide.text1}</h4>
                <p className="mb-5">{slide.text1 === "Faction" ? slide.text1 + " " + slide.text2 : slide.text2}</p>
                <Button className="modal-btn" onClick={() => { toggleClose(); userData.r === "r" ? history.push(`/babble`) : history.push(`/cybot`) }} ><h5 className="mb-0">Get Started</h5></Button>
              </div>
            )
          })}
        </Carousel>
      </Modal>

      <Modal className="bg-shade2 mt-1 mb-1 " isOpen={termOfServiceOpen} size="xl" >
          <Button className="modal-btn close model-close p-2" onClick={() => changeTermOfServiceVisibility(false) }><h5 className="mb-0">Close</h5></Button>
          <TermsOfService/>
      </Modal>

      <Modal className="bg-shade2 mt-1 mb-1 " isOpen={privacyPolicyOpen} size="xl" >
          <Button className="modal-btn close model-close p-2" onClick={() => changePrivacyPolicyVisibility(false) }><h5 className="mb-0">Close</h5></Button>
          <PrivacyPolicy/>
      </Modal>
    </>
  )
}