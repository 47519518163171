import React, { useEffect, useState } from "react";
import { useAtom, atom } from 'jotai'

import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Button, ModalFooter, Tooltip, Spinner } from "reactstrap"
import { Service, Storage } from "services/service";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Config from '../config/Config'
import Avatar from 'react-avatar';
import { AnchorScroll } from "helper";
import { Plus } from 'react-feather'
import FileAvatar from "./FileAvatar";
import ChatBot  from "react-chatbot-kit";
import ChatGPTBot from "./chatbot/ChatGPTBot";

export const cybotId = atom(-1);

export const Likes = ({ args, getAllCommunityData, trigger, setReloadDiscover }) => {
    let userData = Storage.get("userData")

    const [innerCybotId, setInnerCybotId] = useAtom(cybotId);

    const [joinedData, setJoinedData] = useState([])
    const [ownCommunityData, setOwnCommunityData] = useState([])
    const [addCommunityModal, setAddCommunityModal] = useState(false);

    const [resultFiles, setResultFiles] = useState()
    const AddCommunityToggle = () => setAddCommunityModal(!addCommunityModal);

    //ToolTip
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const [editCommuntiyIndex, setEditCommuntiyIndex] = useState([])
    const [deleteCommuntiyIndex, setDeleteCommuntiyIndex] = useState([])

    const [editCommunity, setEditCommunity] = useState(false)
    const [deleteCommunity, setDeleteCommunity] = useState(false)

    const [editImage, setEditImage] = useState("")

    const editCommunityToggle = () => setEditCommunity(!editCommunity)
    const deleteCommunityToggle = () => setDeleteCommunity(!deleteCommunity)

    const [deleteCommunityName, setDeleteCommunityName] = useState([])

    const { control, handleSubmit, setValue } = useForm();

    const [sectionLoading, setSectionLoading] = useState(false)
    const [sectionLoading1, setSectionLoading1] = useState(false)

    useEffect(() => {
        AnchorScroll();
    }, [])

    useEffect(() => {
        getAllJoinedCommunity(true);
    }, [trigger && trigger])

    useEffect(() => {
        getAllCommunity(true);
    }, [trigger && trigger])

    const getAllCommunity = (item) => {
        setSectionLoading(item)
        Service.post({ url: '/activity/groups/search', body: JSON.stringify() })
            .then((response) => {
                if (userData && userData.r === "n") {
                    setOwnCommunityData(response.data.filter((item) => item.isFactionGroup === 0))
                    setSectionLoading(false)
                } else {
                    setOwnCommunityData(response.data)
                    setSectionLoading(false)
                }
            }).catch((error) => {
                setSectionLoading(false)
            })
    }

    const getAllJoinedCommunity = (item) => {
        const params = {
            "limit": 50,
            "page": 1,
            "order": "desc"
        }
        setSectionLoading1(item)
        Service.post({ url: '/activity/groups/joinedGroup/search', body: JSON.stringify(params) })
            .then((response) => {
                const cybotSearch = response.data.find((jd) => jd.GroupName == "Cybot")
                if (!cybotSearch) {
                    // get all groups shown on discover page
                    const params = {
                        limit: 1000,
                        page: 1
                    }
                    let cybot = {}
                    Service.post({ url: '/activity/discover/search', body: JSON.stringify(params) })
                    .then((response) => {
                        cybot = response.data.find((item) => item.name == "Cybot")
                        console.log("testign")
                        if (innerCybotId == -1) setInnerCybotId(cybot.id)
                        // make cybot join compulsory for first time
                        Service.post({ url: '/activity/userSocialGroup/add', body: JSON.stringify({ groupId: cybot.id, userId: userData.id }) })
                        .then((response) => {
                            // getAllJoinedCommunity()
                        }).catch((error) => { })
                    }).catch((error) => {
                    })
                } else {
                    if (innerCybotId == -1) setInnerCybotId(cybotSearch.id)
                }

                setJoinedData(response.data)
                setReloadDiscover(false)
                setSectionLoading1(false)
            }).catch((error) => {
                setSectionLoading1(false)
            })
    }

    const leaveCommunity = (item) => {
        Service.delete({ url: `/activity/userSocialGroup/${item.id}`, body: JSON.stringify() })
            .then((response) => {
                getAllJoinedCommunity(null)
                setReloadDiscover(true)
            }).catch((error) => { })
    }
    const addCommunity = () => {
        AddCommunityToggle();
        setValue("CommunityName", "")
        setValue("CommunityDescription", "")
    }

    const onAddCommunity = (data) => {

        if (resultFiles && resultFiles.filePath) {

            const GroupName = data.CommunityName
            const Description = data.CommunityDescription
            const Image = resultFiles && resultFiles.filePath ? resultFiles.filePath : ""

            if (GroupName === undefined) {
                return false
            } else {
                const params = {
                    groupName: GroupName,
                    description: Description,
                    groupImage: Image
                }
                Service.post({ url: '/activity/groups', body: JSON.stringify(params) })
                    .then((response) => {
                        AddCommunityToggle();
                        getAllCommunity(null);
                        setReloadDiscover(true);
                        setValue("CommunityName", "")
                        setValue("CommunityDescription", "")
                    }).catch((error) => { })
            }
        }

    }
    const editCommunityDetails = (item) => {
        editCommunityToggle();
        setValue("GroupName", item.GroupName)
        setValue("GroupDescription", item.GroupDescription)
        setEditCommuntiyIndex(item.id)
        setEditImage(item.GroupImage)
    }

    const deleteCommunityDetails = (item) => {
        deleteCommunityToggle();
        setDeleteCommuntiyIndex(item.id)
        setDeleteCommunityName(item.GroupName)
    }

    const renderImage = () => {
        if (editImage) {
            return <img src={`${Config.MEDIA_URL}${editImage}`} alt="" width={"200px"} height="100px"></img>
        } else {
            return <img src="" alt="Image not found"></img>
        }
    }

    const onEditCommunitySubmit = (data) => {

        const groupname = data.GroupName
        const groupdescription = data.GroupDescription
        const Image = resultFiles && resultFiles.filePath ? resultFiles.filePath : editImage

        if (groupname === "") {
            return false
        }

        if (groupdescription === "") {
            return false
        }

        const params = {
            groupName: groupname,
            description: groupdescription,
            groupImage: Image
        }
        Service.patch({ url: `/activity/groups/${editCommuntiyIndex}`, body: JSON.stringify(params) })
            .then((response) => {
                editCommunityToggle();
                getAllCommunity(null);
                getAllJoinedCommunity(null);
                setReloadDiscover(true)
            }).catch((error) => { })
    }

    const onDeleteCommunitySubmit = () => {
        Service.delete({ url: `/activity/groups/${deleteCommuntiyIndex}`, body: JSON.stringify() })
            .then((response) => {
                deleteCommunityToggle();
                getAllCommunity(null);
                getAllJoinedCommunity(null);
                setReloadDiscover(true)
            }).catch((error) => { })
    }
    return (
        <>
            <div className="community-scroll container stickey">
                <div className="d-flex justify-content-between align-items-center heading1">
                    <h4 className="mt-0 mt-sm-4">Joined Community</h4>
                    <div id="TooltipExample" className="create-community cursor-pointer mt-0 mt-sm-4 mb-3" onClick={addCommunity}>
                        <Tooltip
                            {...args}
                            isOpen={tooltipOpen}
                            target="TooltipExample"
                            toggle={toggle}
                        >
                            Create a Community
                        </Tooltip>
                        <Plus color="white" />
                    </div>
                    {/* } */}
                </div>
                {
                    joinedData && joinedData.length > 0 &&
                    joinedData.map((item, index) => {
                        return <Row className="mb-3 bg-third listing-data">
                            <Col xl="12" lg="12" md="12" sm="12" xs="12" className="py-2">
                                <Link to={item.isAdmin ? `/cybot/${item.id}` : `/community/${item.id}`}>
                                    <Row>
                                        <Col xl="3" lg="6" md="3" sm="4" xs="6" className="text-sm-left mb-3 img-joined mb-xl-0">
                                            {
                                                item.GroupImage ? <img src={`${Config.MEDIA_URL}${item.GroupImage}`} alt="" className="img-fluid" /> : <Avatar key={item.id} name={item.GroupName.split("").splice(0, 1).join("")} size="45" colors={['#e14eca', '#00f2c3', "#5e72e4"]} className="img-fluid" />
                                            }
                                        </Col>
                                        <Col xl="9" lg="12" md="12" sm="12" xs="12">
                                            <Row>
                                                <Col lg="9" md="9" sm="10" xs="12">
                                                    <h6 className="mb-1">{item.GroupName}</h6>
                                                    <div className="d-flex">
                                                        <p className="text-secondary text-truncate">{item.GroupDescription}</p>
                                                    </div>
                                                </Col>
                                                {item.GroupName !== "Cybot" ?
                                                (<Col lg="3" md="3" sm="2" xs="12" className="d-flex align-items-center justify-content-end mt-2 mt-sm-0 toggle-icon">
                                                    <small className="number2 mr-1">{item.notificationCount}</small>
                                                    <UncontrolledDropdown className='dropdown-user nav-item ml-2' direction="down">
                                                        <DropdownToggle href='/' tag='a' className='align-items-center' onClick={e => e.preventDefault()} >
                                                            <img src={require('.././assets/img/Landing_Page/menu1.png')} alt="" />
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-right optionpost">
                                                            <DropdownItem to='#' onClick={() => leaveCommunity(item)}>
                                                                <span className='align-middle optionpostitem'>Leave Community</span>
                                                            </DropdownItem>
                                                            {
                                                                userData && userData.r === "n" && <><DropdownItem to='#' onClick={() => editCommunityDetails(item)}>
                                                                    <span className='align-middle optionpostitem'>Edit Community</span>
                                                                </DropdownItem>
                                                                    <DropdownItem to='#' onClick={() => deleteCommunityDetails(item)}>
                                                                        <span className='align-middle optionpostitem'>Delete Community</span>
                                                                    </DropdownItem></>
                                                            }
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </Col>) : <></>}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Link>
                            </Col>
                        </Row>
                    })
                }
                {
                    ownCommunityData && ownCommunityData.length > 0 &&
                    ownCommunityData.map((item, index) => {
                        return <Row className="mb-3 bg-third listing-data">
                            <Col xl="12" lg="12" md="12" sm="12" xs="12" className="py-2">
                                <Link to={item.isAdmin ? `/cybot/${item.id}` : `/community/${item.id}`}>
                                    <Row>
                                        <Col xl="3" lg="6" md="3" sm="4" xs="6" className="d-flex align-items-center img-joined mb-3">
                                            {
                                                item.GroupImage ? <img src={`${Config.MEDIA_URL}${item.GroupImage}`} alt="" className="img-fluid" /> : <Avatar key={item.id} name={item.GroupName.split("").splice(0, 1).join("")} size="45" colors={['#e14eca', '#00f2c3', "#5e72e4"]} className="img-fluid" />
                                            }
                                        </Col>
                                        <Col xl="9" lg="12" md="12" sm="12" xs="12">
                                            <Row>
                                                <Col lg="9" md="9" sm="10" xs="12">
                                                    <h6 className="mb-1 mt-3 mt-lg-0">{item.GroupName}</h6>
                                                    <div className="d-flex">
                                                        <p className="text-secondary text-truncate mt-1">{item.GroupDescription}</p>
                                                    </div>
                                                </Col>
                                                <Col lg="3" md="3" sm="2" xs="12"  className="d-flex align-items-center justify-content-end mt-2 mt-sm-0 toggle-icon">
                                                    <small className="number2 mr-1">{item.notificationCount}</small>
                                                    <UncontrolledDropdown className='dropdown-user nav-item ml-2' direction="down">
                                                        <DropdownToggle href='/' tag='a' className='align-items-center' onClick={e => e.preventDefault()} >
                                                            <img src={require('.././assets/img/Landing_Page/menu1.png')} alt="" />
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-right optionpost">
                                                            <DropdownItem to='#' onClick={() => editCommunityDetails(item)}>
                                                                <span className='align-middle optionpostitem'>Edit Community</span>
                                                            </DropdownItem>
                                                            <DropdownItem to='#' onClick={() => deleteCommunityDetails(item)}>
                                                                <span className='align-middle optionpostitem'>Delete Community</span>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Link>
                            </Col>
                        </Row>
                    })
                }
                {
                    ownCommunityData && ownCommunityData.length === 0 && joinedData && joinedData.length === 0 && !sectionLoading && !sectionLoading1 && <div className="bg-fourth text-center">
                        <p>No Communities Joined yet</p>
                    </div>
                }
                {
                    sectionLoading && sectionLoading1 && <div className="bg-fourth text-center">
                        <Spinner color="light" />
                    </div>
                }
            </div>

            {/* <------- Add Community ------------> */}

            <Modal isOpen={addCommunityModal} toggle={AddCommunityToggle} {...args}>
                <ModalHeader toggle={AddCommunityToggle}>Create a Community</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(onAddCommunity)}>
                        <FormGroup>
                            <Label for="name">
                                Name your Community
                            </Label>
                            <Controller
                                name="CommunityName"
                                control={control}
                                id="name"
                                render={({ field }) => <Input placeholder="Enter name" type="text" {...field} />}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="description">
                                Community Description
                            </Label>
                            <Controller
                                name="CommunityDescription"
                                control={control}
                                id="description"
                                render={({ field }) => <Input className="textareacontainer" placeholder="Enter Description" type="textarea" {...field} />}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="photo">
                                Upload a Community Photo
                            </Label>
                            <FileAvatar aspectRatio={4 / 2} setResultFiles={setResultFiles} />
                        </FormGroup>
                        <ModalFooter>
                            <Button color="success" type="submit">
                                Create
                            </Button>{' '}
                            <Button color="secondary" onClick={AddCommunityToggle}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>

            {/* <------- Edit Communtiy ------------> */}

            <Modal isOpen={editCommunity} toggle={editCommunityToggle}>
                <ModalHeader toggle={editCommunityToggle}>Edit Social Community</ModalHeader>
                <hr className="w-100 modal-line" />
                <ModalBody>
                    <Form onSubmit={handleSubmit(onEditCommunitySubmit)}>
                        <FormGroup>
                            <Label for="groupname">
                                Group Name
                            </Label>
                            <Controller
                                name="GroupName"
                                control={control}
                                id="groupname"
                                render={({ field }) => <Input placeholder="Enter Group Name" type="text" {...field} />}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="groupdescription">
                                Group Description
                            </Label>
                            <Controller
                                name="GroupDescription"
                                control={control}
                                id="groupdescription"
                                render={({ field }) => <Input placeholder="Enter Group Description" type="text" {...field} />}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="photo">
                                Update your Community Photo
                            </Label>
                            <FileAvatar aspectRatio={4 / 2} setResultFiles={setResultFiles} />
                        </FormGroup>
                        <FormGroup>
                            {renderImage()}
                        </FormGroup>
                        <ModalFooter>
                            <Button color="success" type="submit">
                                Edit
                            </Button>{' '}
                            <Button color="secondary" onClick={editCommunityToggle}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>

            {/* <------- Delete Community ------------> */}

            <Modal isOpen={deleteCommunity} toggle={deleteCommunityToggle}>
                <ModalHeader toggle={deleteCommunityToggle}>Delete Social Community</ModalHeader>
                <hr className="w-100 modal-line" />
                <ModalBody>
                    <span>Do you really want to delete the Community {deleteCommunityName}?</span>
                    <ModalFooter>
                        <Button color="success" type="submit" onClick={() => onDeleteCommunitySubmit()}>
                            Delete
                        </Button>{' '}
                        <Button color="secondary" onClick={deleteCommunityToggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </>
    )
}