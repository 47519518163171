import React, { useEffect, useState } from "react";
import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Button, ModalFooter, Tooltip, Spinner } from "reactstrap"
import { Menu } from "components/menu";
import { Likes } from "components/Likes";
import { SearchInput } from "components/search";
import { MenuItems } from "components/MenuItems";
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { AnchorScroll } from "helper";
import { Service, Storage } from "services/service";
import Config from '../../config/Config';
import { Link, useHistory } from "react-router-dom";
import Avatar from "react-avatar";
import { Controller, useForm } from "react-hook-form";
import FileAvatar from "../../components/FileAvatar";

const Discover = () => {

    const history = useHistory()

    const [resultFiles, setResultFiles] = useState()

    const [data, setData] = useState([])

    const [trigger, setTrigger] = useState(false)
    const [reloadDiscover, setReloadDiscover] = useState(false)

    const [editCommuntiyIndex, setEditCommuntiyIndex] = useState([])
    const [deleteCommuntiyIndex, setDeleteCommuntiyIndex] = useState([])

    const [editCommunity, setEditCommunity] = useState(false)
    const [deleteCommunity, setDeleteCommunity] = useState(false)

    const [editImage, setEditImage] = useState("")

    const editCommunityToggle = () => setEditCommunity(!editCommunity)
    const deleteCommunityToggle = () => setDeleteCommunity(!deleteCommunity)

    const [deleteCommunityName, setDeleteCommunityName] = useState([])

    const { control, handleSubmit, setValue } = useForm();

    const [pageLoading, setPageLoading] = useState(false)

    useEffect(() => {
        getAllData(true);
    }, [reloadDiscover && reloadDiscover])

    useEffect(() => {
        AnchorScroll();
    }, [])

    const getAllData = (item) => {
        const params = {
            limit: 1000,
            page: 1
        }
        setPageLoading(item)
        Service.post({ url: '/activity/discover/search', body: JSON.stringify(params) })
            .then((response) => {
                const result = response.data.filter((item) => item.isFactionGroup === 0)
                setData(result)
                setTrigger(false)
                setPageLoading(false)
            }).catch((error) => {
                setPageLoading(false)
            })
    }

    const userJoinGroup = (item) => {
        let addUser = Storage.get("userData")
        const params = {
            userId: addUser.id,
            groupId: item.id
        }
        Service.post({ url: '/activity/userSocialGroup/add', body: JSON.stringify(params) })
            .then((response) => {
                setTrigger(true)
                getAllData(null);
            }).catch((error) => { })
    }

    const editCommunityDetails = (item) => {
        editCommunityToggle();
        setValue("GroupName", item.name)
        setValue("GroupDescription", item.description)
        setEditCommuntiyIndex(item.id)
        setEditImage(item.image)
    }

    const deleteCommunityDetails = (item) => {
        deleteCommunityToggle();
        setDeleteCommuntiyIndex(item.id)
        setDeleteCommunityName(item.name)
    }

    const renderImage = () => {
        if (editImage) {
            return <img src={`${Config.MEDIA_URL}${editImage}`} alt="" width={"200px"} height="100px"></img>
        } else {
            return <img src="" alt="Image not found"></img>
        }
    }

    const onEditCommunitySubmit = (data) => {

        const groupname = data.GroupName
        const groupdescription = data.GroupDescription
        const Image = resultFiles && resultFiles.filePath ? resultFiles.filePath : editImage

        if (groupname === "") {
            return false
        }

        if (groupdescription === "") {
            return false
        }

        const params = {
            groupName: groupname,
            description: groupdescription,
            groupImage: Image
        }
        Service.patch({ url: `/activity/groups/${editCommuntiyIndex}`, body: JSON.stringify(params) })
            .then((response) => {
                editCommunityToggle();
                getAllData(null);
                setTrigger(true)
            }).catch((error) => { })
    }

    const onDeleteCommunitySubmit = () => {
        Service.delete({ url: `/activity/groups/${deleteCommuntiyIndex}`, body: JSON.stringify() })
            .then((response) => {
                deleteCommunityToggle();
                getAllData(null);
                setTrigger(true)
            }).catch((error) => { })
    }

    return (
        <>
            <div className="px-4 bg-right">
                <SearchInput />
                <Row>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="mt-4">
                        <div className="mx-sm-5 px-sm-5 mx-lg-0 px-lg-0">
                            <Menu />
                        </div>
                        <div className="d-none d-lg-block mt-lg-4 stickey">
                            <MenuItems />
                        </div>
                    </Col>
                    <Col xl="6" lg="6" md="12" sm="12" xs="12" >
                        <div className="middle-info-bg h-lg-100 p-3 p-sm-4 mt-4">
                            <h3>Communities</h3>
                            {
                                pageLoading ? <div className="bg-primary p-3 m-3 text-center"><Spinner color="light" /></div> : <Row>
                                    {
                                        data && data.length > 0 && data.map((item, index) => {
                                            return <Col xs="12" sm="6" md="6" lg="6" xl="6">
                                                <div className="bg-primary my-3 mx-1">
                                                    {
                                                        item.canAccess ? <UncontrolledDropdown className='dropdown-user nav-item d-flex justify-content-end pl-0 pr-2 pt-2' direction="down">
                                                            <DropdownToggle href='/' tag='a' className='align-items-center' onClick={e => e.preventDefault()} >
                                                                <img src={require('../../assets/img/Landing_Page/menu1.png')} alt="" />
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-right optionpost">
                                                                <DropdownItem to='#' onClick={() => editCommunityDetails(item)}>
                                                                    <span className='align-middle optionpostitem'>Edit Community</span>
                                                                </DropdownItem>
                                                                <DropdownItem to='#' onClick={() => deleteCommunityDetails(item)}>
                                                                    <span className='align-middle optionpostitem'>Delete Community</span>
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown> : <div className="d-flex justify-content-end pl-0 pr-2 pt-2">
                                                            <Button size="sm" className="modal-btn" onClick={() => userJoinGroup(item)} type="button">{
                                                                <div className="d-flex"><AiOutlineCheckCircle size={"20px"} className="mr-1" /><span>Join </span></div>
                                                            }</Button>
                                                        </div>
                                                    }
                                                    <div>
                                                        <Link to={item.isAdmin ? `/cybot/${item.id}` : `/community/${item.id}`}>
                                                            <Row className="d-flex justify-content-center p-4">
                                                                <Col xs="12" sm="12" md="4" lg="12" xl="3" className="p-0 text-center text-md-left mb-3 text-lg-center text-xl-left">
                                                                    {
                                                                        item.image ? <img src={`${Config.MEDIA_URL}${item.image}`} className="img-fluid" width={130} alt="" /> : <Avatar name={item.name.split("").splice(0, 1).join("")} size="60" colors={['#e14eca', '#00f2c3']} />
                                                                    }
                                                                </Col>
                                                                <Col xs="12" sm="12" md="8" lg="12" xl="9" className=" text-center text-md-left text-lg-center text-xl-left">
                                                                    <h5 className="mb-1">{item.name}</h5>
                                                                    <div className="d-flex flex-column mt-3">
                                                                        <span className="text-secondary mr-2 text-truncate">{item.description}</span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Col>
                                        })
                                    }
                                </Row>
                            }
                        </div>
                    </Col>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="p-0 my-5 mt-sm-0">
                        <Likes trigger={trigger} setReloadDiscover={setReloadDiscover} />
                    </Col>
                </Row>

                {/* <------- Edit Communtiy ------------> */}

                <Modal isOpen={editCommunity} toggle={editCommunityToggle}>
                    <ModalHeader toggle={editCommunityToggle}>Edit Social Community</ModalHeader>
                    <hr className="w-100 modal-line" />
                    <ModalBody>
                        <Form onSubmit={handleSubmit(onEditCommunitySubmit)}>
                            <FormGroup>
                                <Label for="groupname">
                                    Group Name
                                </Label>
                                <Controller
                                    name="GroupName"
                                    control={control}
                                    id="groupname"
                                    render={({ field }) => <Input placeholder="Enter Group Name" type="text" {...field} />}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="groupdescription">
                                    Group Description
                                </Label>
                                <Controller
                                    name="GroupDescription"
                                    control={control}
                                    id="groupdescription"
                                    render={({ field }) => <Input placeholder="Enter Group Description" type="text" {...field} />}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="photo">
                                    Update your Community Photo
                                </Label>
                                <FileAvatar aspectRatio={4 / 2} setResultFiles={setResultFiles} />
                            </FormGroup>
                            <FormGroup>
                                {renderImage()}
                            </FormGroup>
                            <ModalFooter>
                                <Button color="success" type="submit">
                                    Edit
                                </Button>{' '}
                                <Button color="secondary" onClick={editCommunityToggle}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </Form>
                    </ModalBody>
                </Modal>

                {/* <------- Delete Community ------------> */}

                <Modal isOpen={deleteCommunity} toggle={deleteCommunityToggle}>
                    <ModalHeader toggle={deleteCommunityToggle}>Delete Social Community</ModalHeader>
                    <hr className="w-100 modal-line" />
                    <ModalBody>
                        <span>Do you really want to delete the Community {deleteCommunityName}?</span>
                        <ModalFooter>
                            <Button color="success" type="submit" onClick={() => onDeleteCommunitySubmit()}>
                                Delete
                            </Button>{' '}
                            <Button color="secondary" onClick={deleteCommunityToggle}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            </div >
        </>
    )
}

export default Discover