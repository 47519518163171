import React, { useEffect, useState } from "react";
import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { Menu } from "components/menu";
import { Likes } from "components/Likes";
import { SearchInput } from "components/search";
import { MenuItems } from "components/MenuItems";
import { AnchorScroll } from "helper";
import { Service } from "services/service";
import { Link } from "react-router-dom";
import Config from '../../config/Config'
import Avatar from "react-avatar";

const Community = () => {

    const [joinedData, setJoinedData] = useState([])

    useEffect(() => {
        AnchorScroll();
    }, [])

    useEffect(() => {
        getAllJoinedCommunity();
    }, [])

    const getAllJoinedCommunity = () => {
        const params = {
            "limit": 50,
            "page": 1,
            "order": "desc"
        }
        Service.post({ url: '/activity/groups/joinedGroup/search', body: JSON.stringify(params) })
            .then((response) => {
                setJoinedData(response.data)
            }).catch((error) => { })
    }

    const leaveCommunity = (item) => {
        Service.delete({ url: `/activity/userSocialGroup/${item.id}`, body: JSON.stringify() })
            .then((response) => {
                getAllJoinedCommunity()
            }).catch((error) => { })
    }

    return (
        <>
            <div className="px-4 bg-right">
                <SearchInput />
                <Row>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="mt-4">
                        <div className="mx-sm-5 px-sm-5 mx-lg-0 px-lg-0">
                            <Menu />
                        </div>
                        <div className="d-none d-lg-block mt-lg-4 stickey">
                            <MenuItems />
                        </div>
                    </Col>
                    <Col xl="6" lg="6" md="12" sm="12" xs="12" >
                        <div className="middle-info-bg h-lg-100 p-3 p-sm-4 mt-4">
                            <h3>Joined Communities</h3>
                            {
                                joinedData.map((item) => {
                                    return <div className="bg-primary p-3 mb-3">
                                        <Row className="d-flex align-items-center">
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Link to={item.isAdmin ? `/cybot` : `/community/${item.id}`}>
                                                    <Row>
                                                        <Col lg="3" md="3" sm="3" xs="12" className="text-sm-left">
                                                            {
                                                                item.GroupImage ? <img src={`${Config.MEDIA_URL}${item.GroupImage}`} alt="" /> : <Avatar key={item.id} name={item.GroupName.split("").splice(0, 1).join("")} size="50" colors={['#e14eca', '#00f2c3']} />
                                                            }
                                                        </Col>
                                                        <Col lg="9" md="12" sm="12" xs="12">
                                                            <Row>
                                                                <Col lg="9" md="9" sm="10" xs="12" className="">
                                                                    <h5 className="mb-1 mt-3 mt-lg-0">{item.GroupName}</h5>
                                                                    <div className="d-flex">
                                                                        <h6 className="mt-1">{item.GroupDescription}</h6>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="3" md="3" sm="2" xs="12" className="d-flex align-items-center justify-content-end mt-2 mt-sm-0 toggle-icon">
                                                                    <small className="number2 mr-1">{item.notificationCount}</small>
                                                                    <UncontrolledDropdown className='dropdown-user nav-item ml-2' direction="down">
                                                                        <DropdownToggle href='/' tag='a' className='align-items-center' onClick={e => e.preventDefault()} >
                                                                            <img src={require('.././../assets/img/Landing_Page/menu1.png')} className="" alt="" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-right optionpost">
                                                                            <DropdownItem to='#' onClick={() => leaveCommunity(item)}>
                                                                                <span className='align-middle optionpostitem'>Leave Community</span>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>
                                })
                            }
                        </div>
                    </Col>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="p-0 my-5 mt-sm-0">
                        <Likes />
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default Community