import React from "react";
import { Row, Col } from "reactstrap"
import { Menu } from "components/menu";
import { Likes } from "components/Likes";
import { SearchInput } from "components/search";
import { MenuItems } from "components/MenuItems";

const EP1 = () => {
    return (
        <>
            <div className="px-4 bg-right">
                <SearchInput />
                <Row>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="mt-4">
                        <div className="mx-sm-5 px-sm-5 mx-lg-0 px-lg-0">
                            <Menu />
                        </div>
                        <div className="d-none d-lg-block mt-lg-4">
                            <MenuItems />
                        </div>
                    </Col>
                    <Col xl="6" lg="6" md="12" sm="12" xs="12" >
                        <div className="middle-info-bg h-lg-100 p-2 p-xl-4 mt-4">
                            <div className="bg-primary m-3">
                                <img src={require('../../assets/img/Landing_Page/Image20.png')} height={'fit-content'} className="image-width" alt="" />
                                <div className="d-flex flex-column flex-sm-row justify-content-between mx-4 py-4">
                                    <div>
                                        <h3 className="mb-1 text-center text-sm-left">EP1</h3>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <p>About</p>
                                            <h4 className="mb-2 ml-2">6.18K</h4>
                                            <p className="ml-2">Total Member</p>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                                        <img src={require('../../assets/img/Landing_Page/Group.png')} width={130} alt="" />
                                        <div className="text-center">
                                            <p>William And <span className="text-white">5 Other</span></p>
                                            <p className="ml-sm-2">Are Member</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-primary m-3">
                                <div className="d-flex align-items-center justify-content-between py-3">
                                    <div className="d-flex align-items-center">
                                        <img src={require('../../assets/img/Landing_Page/Ellipse.png')} alt="" />
                                        <h4 className="ml-1">Cybot</h4>
                                    </div>
                                    <img src={require('../../assets/img/Landing_Page/menu1.png')} className="mr-3 mb-3" alt="" />
                                </div>
                                <p className="mx-3">Hi Everyone <img src={require('../../assets/img/Landing_Page/Wave.png')} alt="" /></p>
                                <p className="mx-3 mb-4 text-capitalize text-justify">Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery of type and scrambled it to make a type specimen book. It has survived @outreach</p>
                                <p className="mx-3 mb-4 text-capitalize text-justify">Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery of type and scrambled it to make a type specimen book. It has survived @outreach</p>
                                <p className="mx-3 mb-4 text-capitalize text-justify">Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery of type and scrambled it to make a type specimen book. It has survived @outreach</p>
                                <p className="mx-3 pb-4 text-capitalize text-justify">Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gallery of type and scrambled it to make a type specimen book. It has survived @outreach</p>

                            </div>
                        </div>
                    </Col>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="p-0 my-5 mt-sm-0">
                        <Likes />
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default EP1